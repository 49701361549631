import React, { Component } from "react";
import AddLocation from "./add-location.component";
import EditLocation from "./edit-location.component";
import LocationService from "../../services/location.service";
import Table from "react-bootstrap/Table";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RiEditBoxLine, RiDeleteBin2Line } from "react-icons/ri";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { logout } from "../../actions/auth";

class Locations extends Component {
	constructor(props) {
		super(props);

		this.retrieveLocations = this.retrieveLocations.bind(this);
		this.addLocationClick = this.addLocationClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleEditClose = this.handleEditClose.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);

		this.state = {
			locations: [],
			showadd: false,
			showedit: false,
			editStatus: false,
			offset: 0,
			perPage: 10,
			currentPage: 0,
		};
	}

	componentDidMount() {
		this.retrieveLocations();
	}

	retrieveLocations() {
		// get Locations Data
		LocationService.getAll()
			.then((response) => {
				// this.setState({
				// 	locations: response.data,
				// });
				const locations = response.data.sort(function (a, b) {
					var nameA = a.locationname.toUpperCase(); // ignore upper and lowercase
					var nameB = b.locationname.toUpperCase(); // ignore upper and lowercase
					if (nameA < nameB) {
						return -1; //nameA comes first
					}
					if (nameA > nameB) {
						return 1; // nameB comes first
					}
					return 0; // names must be equal
				});
				const slice = locations.slice(
					this.state.offset,
					this.state.offset + this.state.perPage
				);
				const postData = slice.map((location, index) => (
					<tr key={index}>
						<td>{location.locationname}</td>
						<td>
							<div className="action-button-grid m-t-0">
								<RiEditBoxLine
									size={22}
									onClick={() => this.onClickEdit(location.id)}
								/>
								<RiDeleteBin2Line
									size={22}
									onClick={() =>
										this.onClickDelete(location.id, location.locationname)
									}
								/>
							</div>
						</td>
					</tr>
				));

				this.setState({
					pageCount: Math.ceil(locations.length / this.state.perPage),
					postData,
				});
			})
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
				}
			});
	}

	handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * this.state.perPage;

		this.setState(
			{
				currentPage: selectedPage,
				offset: offset,
			},
			() => {
				this.retrieveLocations();
			}
		);
	};

	addLocationClick() {
		this.setState({
			showadd: true,
		});
	}

	handleClose() {
		this.setState({
			showadd: false,
		});
	}

	handleEditClose() {
		this.setState({
			showedit: false,
		});
	}

	onClickEdit(locationid) {
		//alert("edit" + projectid);
		this.setState({
			editStatus: true,
			editLocationId: locationid,
			showedit: true,
		});
	}

	onClickDelete(locationid, name) {
		confirmAlert({
			title: `Confirm to Delete ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteLocation(locationid),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteLocation(locationid) {
		LocationService.delete(locationid)
			.then((response) => {
				this.retrieveLocations();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { showadd, showedit, editStatus, postData } = this.state; //locations
		const { user: currentUser } = this.props;
		//console.log(currentUser);
		if (!currentUser || !currentUser.project_roles.add) {
			return <Redirect to="/login" />;
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-sm-8">
						<h4>
							Locations List
							<button
								className="btn btn-sm btn-primary float-right"
								type="button"
								onClick={this.addLocationClick}
							>
								Add Location
							</button>
						</h4>
						{editStatus ? (
							<EditLocation
								showedit={showedit}
								handleEditClose={this.handleEditClose}
								key={this.state.editLocationId}
								retrieveLocations={this.retrieveLocations}
								editLocationId={this.state.editLocationId}
							/>
						) : (
							<AddLocation
								showadd={showadd}
								handleClose={this.handleClose}
								retrieveLocations={this.retrieveLocations}
							/>
						)}

						<hr />
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Location</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>{postData && <>{postData}</>}</tbody>
						</Table>
						<ReactPaginate
							previousLabel={"prev"}
							nextLabel={"next"}
							breakLabel={"..."}
							breakClassName={"break-me"}
							pageCount={this.state.pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={this.handlePageClick}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>
					</div>
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Locations);
