import http from "../http-common";
import authHeader from './auth-header';

class ProjectDataService {
  getAll() {
    return http.get("/projects", { headers: authHeader() });
  }

  get(id) {
    return http.get(`/projects/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/projects", data);
  }

  update(id, data) {
    return http.put(`/projects/${id}`, data , { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/projects/${id}` , { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/projects` , { headers: authHeader() });
  }

  findByTitle(title) {
    return http.get(`/projects?title=${title}` , { headers: authHeader() });
  }

  cloneProject(data) {
    return http.post("/projects/clone", data , { headers: authHeader() });
  }

}

export default new ProjectDataService();