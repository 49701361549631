import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { register } from "../../actions/auth";
import RoleDataService from "../../services/role.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};



const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
   this.onChangeFullName = this.onChangeFullName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);

    this.state = {
      username: "",
      fullname:"",
      password: "",
      successful: false,
      showmessage: true,
      role: '',
      roleOptions: []
    };
  }

  componentDidMount() {
    this.retrieveRoles();
  }

  retrieveRoles() {
    RoleDataService.getAll()
      .then(response => {
        this.setState({
          roleOptions: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeFullName(e) {
    this.setState({
      fullname: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeRole(e) {
    this.setState({ role: e.target.value });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
      showmessage: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          register(this.state.username, this.state.fullname, this.state.password, this.state.role)
        )
        .then(() => {
          this.setState({
            successful: true,
          });

          setTimeout(() => {
            this.newForm();
          }, 3000)

          this.props.retrieveUsers();
          //window.location.reload();
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }
  newForm() {
    this.setState({
      id: null,
      username: "",
      fullname: "",
      password: "",
      role: 'user',
      successful: false,
      showmessage: false,
    });
  }

  render() {
    const { message } = this.props;


    return (
      <div className="col-md-12">
        <div className="card card-container">
          {/* <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
            /> */}
          <h4>Add New User</h4>
          <hr />
          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <Input
                    type="text"
                    className="form-control m-t-10"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="fullname">Full Name</label>
                  <Input
                    type="text"
                    className="form-control m-t-10"
                    name="fullname"
                    value={this.state.fullname}
                    onChange={this.onChangeFullName}
                    validations={[required]}
                  />
                </div>

                <div className="form-group m-b-10">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    className="form-control  m-t-10"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="form-group m-b-10">
                  <label htmlFor="role">Select Role</label>
                  <select className="form-control m-t-10"
                    id="role"
                    name="role"
                    validations={[required]}
                    value={this.state.role}
                    onChange={this.onChangeRole}><option value="">Select Role</option>
                    {this.state.roleOptions.map((option, index) => <option value={option.name} key={index}>{option.name}</option>)}
                  </select>
                </div>

                <div className="form-group m-b-10">
                  <button className="btn btn-primary btn-block">Add User</button>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                {this.state.showmessage && (
                  <div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                    {message}
                  </div>
                )}
              </div>

            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);
