import React, { Component } from "react";
import { Navbar, Nav, Container } from "react-bootstrap"; //NavDropdown
import "bootstrap/dist/css/bootstrap.min.css";
//import logo from './logo.svg';
import { connect } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom"; //Link
//import AddFolder from "./components/folders/add-folder.component";
import FolderList from "./components/folders/list-folder.component";
import ProjectList from "./components/projects/list-project.component";
//import Folder from "./components/folders/folder.component";
import "./App.css";
import Login from "./components/auth/login.component";
import Register from "./components/auth/register.component";
//import Home from "./components/homepage.component";
import Profile from "./components/users/profile.component";
import Users from "./components/users/users.component";
import LocationList from "./components/locations/locations.component";
import TemplateList from "./components/projects/list-template.component";
import LogsList from "./components/logs/list-log.component";

import CustomFieldList from "./components/customfields/customfields.component";
import Roles from "./components/roles/roles.component";
import BoardUser from "./components/users/user.component";
import BoardModerator from "./components/users/moderator.component";
import BoardAdmin from "./components/users/admin.component";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Scrollbars } from "react-custom-scrollbars";

class App extends Component {
	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);
		this.print = this.print.bind(this);
		//this.handleUnload = this.handleUnload.bind(this);

		this.state = {
			showModeratorBoard: false,
			showAdminBoard: false,
			currentUser: undefined,
		};

		history.listen((location) => {
			props.dispatch(clearMessage()); // clear message when changing location
		});
	}

	componentDidMount() {
		const user = this.props.user;
		this.print();

		if (user) {
			this.setState({
				currentUser: user,
				showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
				showAdminBoard: user.roles.includes("ROLE_ADMIN"),
			});
			//window.addEventListener("beforeunload", this.handleUnload);
		}
	}

	// componentWillUnmount() {
	// 	window.removeEventListener("beforeunload", this.handleUnload);
	// }

	// handleUnload(e) {
	// 	window.localStorage.clear();
	// }

	// handleUnload(e) {
	// 	console.log(e);
	// 	var message = "You may be logged out of the site...";

	// 	(e || window.event).returnValue = message; //Gecko + IE
	// 	try {
	// 		window.localStorage.clear();
	// 		return message;
	// 	} finally {
	// 	}
	// }

	logOut() {
		this.props.dispatch(logout());
	}

	print() {
		document.addEventListener("contextmenu", (event) => event.preventDefault());
		window.addEventListener("keyup", kPress, false);
		function kPress(e) {
			var c = e.keyCode || e.charCode;
			if (c === 44) {
				var inpFld = document.createElement("input");
				inpFld.setAttribute("value", ".");
				inpFld.setAttribute("width", "0");
				inpFld.style.height = "0px";
				inpFld.style.width = "0px";
				inpFld.style.border = "0px";
				document.body.appendChild(inpFld);
				inpFld.select();
				document.execCommand("copy");
				inpFld.remove(inpFld);
				console.log(c);
			}
		}
	}

	render() {
		const { currentUser, showAdminBoard } = this.state; //showModeratorBoard
		//console.log(currentUser);
		return (
			<Scrollbars className="main-scroll" style={{ height: "100vh" }}>
				<Router history={history}>
					<div>
						<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
							<Container>
								<Navbar.Brand>
									<Link to={"/projects"} className="nav-link">
										Projects
									</Link>
								</Navbar.Brand>
								<Navbar.Toggle aria-controls="responsive-navbar-nav" />
								<Navbar.Collapse id="responsive-navbar-nav">
									<Nav className="me-auto">
										{showAdminBoard && (
											<Link to={"/roles"} className="nav-link">
												Roles
											</Link>
										)}
										{showAdminBoard && (
											<Link to={"/users"} className="nav-link">
												Users
											</Link>
										)}
										{currentUser && currentUser.project_roles.add && (
											<Link to={"/customfields"} className="nav-link">
												Custom Fields
											</Link>
										)}
										{currentUser && currentUser.project_roles.add && (
											<Link to={"/locations"} className="nav-link">
												Locations
											</Link>
										)}
										{showAdminBoard && (
											<Link to={"/templates"} className="nav-link">
												Templates
											</Link>
										)}
										{showAdminBoard && (
											<Link to={"/logs"} className="nav-link">
												Logs
											</Link>
										)}
									</Nav>
									{currentUser ? (
										<Nav className="ml-auto menu-right">
											{/* <Nav.Link href="/">{currentUser.username}</Nav.Link> */}
											<Link to={"/"} className="nav-link">
												{currentUser.username}
											</Link>
											<Nav.Link
												eventKey={2}
												href="/login"
												onClick={this.logOut}
											>
												Logout
											</Nav.Link>
										</Nav>
									) : (
										<Nav className="ml-auto menu-right">
											<Link to={"/login"} className="nav-link">
												Login
											</Link>
										</Nav>
									)}
								</Navbar.Collapse>
							</Container>
						</Navbar>
						{/* <nav className="navbar navbar-expand navbar-dark bg-dark">
						<a href="/projects" className="navbar-brand p-l-10">
							Projects
						</a>
						<div className="navbar-nav mr-auto">
							{showAdminBoard && (
								<li className="nav-item">
									<Link to={"/roles"} className="nav-link">
										Roles
									</Link>
								</li>
							)}

							{showAdminBoard && (
								<li className="nav-item">
									<Link to={"/users"} className="nav-link">
										Users
									</Link>
								</li>
							)}

							{currentUser && currentUser.project_roles.add && (
								<li className="nav-item">
									<Link to={"/customfields"} className="nav-link">
										Custom Fields
									</Link>
								</li>
							)}

							{currentUser && currentUser.project_roles.add && (
								<li className="nav-item">
									<Link to={"/locations"} className="nav-link">
										Locations
									</Link>
								</li>
							)}

							{showAdminBoard && (
								<li className="nav-item">
									<Link to={"/templates"} className="nav-link">
										Templates
									</Link>
								</li>
							)}

							{showAdminBoard && (
								<li className="nav-item">
									<Link to={"/logs"} className="nav-link">
										Logs
									</Link>
								</li>
							)}
						</div>

						{currentUser ? (
							<div className="navbar-nav ml-auto menu-right">
								<li className="nav-item">
									<Link to={"/"} className="nav-link">
										{currentUser.username}
									</Link>
								</li>
								<li className="nav-item">
									<a href="/login" className="nav-link" onClick={this.logOut}>
										Logout
									</a>
								</li>
							</div>
						) : (
							<div className="navbar-nav ml-auto menu-right">
								<li className="nav-item p-r-10">
									<Link to={"/login"} className="nav-link">
										Login
									</Link>
								</li>
							</div>
						)}
					</nav> */}

						<div className="container-fluid mt-3">
							<Switch>
								{/* <Route exact path={["/", "/home"]} component={Home} /> */}
								<Route exact path="/login" component={Login} />
								<Route exact path="/register" component={Register} />
								<Route exact path="/profile" component={Profile} />
								<Route exact path="/roles" component={Roles} />
								<Route exact path="/users" component={Users} />
								<Route exact path="/customfields" component={CustomFieldList} />
								<Route exact path="/locations" component={LocationList} />
								<Route exact path="/templates" component={TemplateList} />
								<Route exact path="/logs" component={LogsList} />
								<Route path="/user" component={BoardUser} />
								<Route path="/mod" component={BoardModerator} />
								<Route path="/admin" component={BoardAdmin} />
								<Route
									exact
									path={["/", "/projects"]}
									component={ProjectList}
								/>
								{/* <Route exact path={["/folders"]} component={FolderList} />
              <Route exact path="/add-folder" component={AddFolder} /> */}

								{/* <Route exact path="/folders-tree/:id" component={FolderList} /> */}
								<Route exact path="/folders/:id" component={FolderList} />
							</Switch>
						</div>
					</div>
				</Router>
			</Scrollbars>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(App);
