import React, { Component } from "react";
import AddRole from "./add-role.component";
import EditRole from "./edit-role.component";
import RoleDataService from "../../services/role.service";
import Table from "react-bootstrap/Table";
import { RiEditBoxLine, RiDeleteBin2Line } from "react-icons/ri";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { logout } from "../../actions/auth";

import { connect } from "react-redux";

class Roles extends Component {
	constructor(props) {
		super(props);

		this.retrieveRoles = this.retrieveRoles.bind(this);
		this.addRoleClick = this.addRoleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleEditClose = this.handleEditClose.bind(this);

		this.state = {
			roles: [],
			showadd: false,
			showedit: false,
			editStatus: false,
		};
	}

	componentDidMount() {
		this.retrieveRoles();
	}

	retrieveRoles() {
		// get Roles Data
		RoleDataService.getAll()
			.then((response) => {
				this.setState({
					roles: response.data,
				});
				console.log(this.state.roles);
			})
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
				}
			});
	}

	addRoleClick() {
		// alert();
		this.setState({
			showadd: true,
		});
	}

	handleClose() {
		this.setState({
			showadd: false,
		});
	}

	handleEditClose() {
		this.setState({
			showedit: false,
		});
	}

	onClickEdit(roleid) {
		//alert("edit" + projectid);
		this.setState({
			editStatus: true,
			editRoleId: roleid,
			showedit: true,
		});
	}

	onClickDelete(roleid, name) {
		confirmAlert({
			title: `Confirm to Delete ${name} role`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteRole(roleid),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteRole(roleid) {
		RoleDataService.delete(roleid)
			.then((response) => {
				this.retrieveRoles();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { roles, showadd, showedit, editStatus } = this.state;
		const { user: currentUser } = this.props;

		if (!currentUser || currentUser.roles !== "ROLE_ADMIN") {
			return <Redirect to="/login" />;
		}
		// console.log(roles);
		return (
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h4>
							Roles List
							<button
								className="btn btn-sm btn-primary float-right"
								type="button"
								onClick={this.addRoleClick}
							>
								Add Role
							</button>
						</h4>
						{editStatus ? (
							<EditRole
								showedit={showedit}
								handleEditClose={this.handleEditClose}
								key={this.state.editRoleId}
								retrieveRoles={this.retrieveRoles}
								editRoleId={this.state.editRoleId}
							/>
						) : (
							<AddRole
								showadd={showadd}
								handleClose={this.handleClose}
								retrieveRoles={this.retrieveRoles}
							/>
						)}

						<hr />
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th rowSpan="2">Role</th>
									<th colSpan="3">Project</th>
									<th colSpan="6">Folder</th>
									<th rowSpan="2">Actions</th>
								</tr>
								<tr>
									<th>Add</th>
									<th>Edit</th>
									<th>Delete</th>
									<th>Add</th>
									<th>Move</th>
									<th>Edit</th>
									<th>Download</th>
									<th>Restricted</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>
								{roles &&
									roles.map((role, index) => (
										<tr key={index}>
											<td>{role.name}</td>
											<td>
												{role.projects.add ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.projects.edit ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.projects.delete ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.folders.add ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.folders.move ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.folders.edit ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.folders.download ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.folders.restricted ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												{role.folders.delete ? (
													<FcCheckmark size={22} />
												) : (
													<FcCancel size={22} />
												)}
											</td>
											<td>
												<div className="action-button-grid m-t-0">
													<RiEditBoxLine
														size={22}
														onClick={() => this.onClickEdit(role.id)}
													/>
													<RiDeleteBin2Line
														size={22}
														onClick={() =>
															this.onClickDelete(role.id, role.name)
														}
													/>
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</div>
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Roles);
