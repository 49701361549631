import React, { Component } from "react";
import AddCustomField from "./add-customfield.component";
import CustomFieldService from "../../services/customfield.service";
import Table from "react-bootstrap/Table";
import { Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RiDeleteBin2Line } from "react-icons/ri"; //RiEditBoxLine
import { connect } from "react-redux";
import { logout } from "../../actions/auth";

class CustomFields extends Component {
	constructor(props) {
		super(props);

		this.retrieveCustomFields = this.retrieveCustomFields.bind(this);
		this.addCustomFieldClick = this.addCustomFieldClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		//this.handleEditClose = this.handleEditClose.bind(this);

		this.state = {
			customfields: [],
			showadd: false,
			// showedit: false,
			// editStatus:false
		};
	}

	componentDidMount() {
		this.retrieveCustomFields();
	}

	addCustomFieldClick() {
		this.setState({
			showadd: true,
		});
	}

	handleClose() {
		this.setState({
			showadd: false,
		});
	}

	// handleEditClose() {
	//   this.setState({
	//     showedit: false
	//   });
	// }

	retrieveCustomFields() {
		// get customfields Data
		CustomFieldService.getAll()
			.then((response) => {
				this.setState({
					customfields: response.data,
				});
			})
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
				}
			});
	}

	onClickDelete(customFieldId, name) {
		confirmAlert({
			title: `Confirm to Delete ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteCustomField(customFieldId),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteCustomField(customFieldId) {
		CustomFieldService.delete(customFieldId)
			.then((response) => {
				this.retrieveCustomFields();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { customfields, showadd } = this.state;
		const { user: currentUser } = this.props;
		// console.log(currentUser);
		//  alert(currentUser.roles);
		if (!currentUser || !currentUser.project_roles.add) {
			return <Redirect to="/login" />;
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-sm-8">
						<h4>
							Custom Fields List
							<button
								className="btn btn-sm btn-primary float-right"
								type="button"
								onClick={this.addCustomFieldClick}
							>
								Add Custom Field
							</button>
						</h4>
						<AddCustomField
							showadd={showadd}
							handleClose={this.handleClose}
							retrieveCustomFields={this.retrieveCustomFields}
						/>
						<hr />
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th>Custom Field</th>
									<th>Type</th>
									<th>Options</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{customfields &&
									customfields
										.sort(function (a, b) {
											var nameA = a.customfieldname.toUpperCase(); // ignore upper and lowercase
											var nameB = b.customfieldname.toUpperCase(); // ignore upper and lowercase
											if (nameA < nameB) {
												return -1; //nameA comes first
											}
											if (nameA > nameB) {
												return 1; // nameB comes first
											}
											return 0; // names must be equal
										})
										.map((customfield, index) => (
											<tr key={index}>
												<td>{customfield.customfieldname}</td>
												<td>
													{customfield.fieldtype === "txt" ? "Text Field" : ""}
													{customfield.fieldtype === "mc"
														? "Multiple Choice"
														: ""}
													{customfield.fieldtype === "ms"
														? "Multiple Select"
														: ""}
												</td>
												<td>{customfield.mcmsoptions.join(", ")}</td>
												<td>
													<div className="action-button-grid m-t-0">
														{/* <RiEditBoxLine size={22} onClick={() => this.onClickEdit(customfield.id)} /> */}
														<RiDeleteBin2Line
															size={22}
															onClick={() =>
																this.onClickDelete(
																	customfield.id,
																	customfield.customfieldname
																)
															}
														/>
													</div>
												</td>
											</tr>
										))}
							</tbody>
						</Table>
					</div>
					{/* <div className="col-sm-6">     
               <AddCustomField showadd={showadd} handleClose={this.handleClose} retrieveCustomFields={this.retrieveCustomFields} /> 
            </div>            */}
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(CustomFields);
