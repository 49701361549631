import React, { Component } from "react";

import { connect } from "react-redux";

class Dynamicmc extends Component {
  constructor(props) {
    super(props);

    this.handleOnFieldOption = this.handleOnFieldOption.bind(this);
    const { selectedValue } = this.props;

    this.state = {
      fieldoption: selectedValue || '',
      msOptionsArray: this.props.msOptionsArray,
      label: this.props.label
    }

    //  console.log(this.state.msOptionsArray);
  }

  handleOnFieldOption(e) {
    this.setState({
      fieldoption: e.target.value
    });
    this.props.setCustomFields(this.props.id, e.target.value, this.props.label);
  }

  render() {
    const { index } = this.props;
    const { msOptionsArray, label, fieldoption } = this.state;
    return (
      <div className="col-sm-6" key={index}>
        <label>{label}</label>
        <select className="form-control m-t-10"
          name={this.props.id}
          value={fieldoption}
          onChange={(e) => this.handleOnFieldOption(e)}>
          <option value="">Select {label}</option>
          {msOptionsArray.map((option, optindex) => {

            // if (this.state.location === option.id) {
            //   <option value={option} key={`mc-${optindex}`}>{option}</option>
            // } else {
            return <option value={option} key={`mc-${optindex}`}>{option}</option>
            //}
          })}
        </select>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Dynamicmc);

