import React, { Component } from "react";
//import ProjectDataService from "../../services/project.service";
import TemplateDataService from "../../services/template.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Modal from "react-bootstrap/Modal";
//import  { Redirect } from 'react-router-dom'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

class AddTemplate extends Component {
	constructor(props) {
		super(props);
		this.onChangeTemplateName = this.onChangeTemplateName.bind(this);
		this.saveTemplate = this.saveTemplate.bind(this);
		this.newTemplate = this.newTemplate.bind(this);
		this.handleAddTemplate = this.handleAddTemplate.bind(this);
		this.handleTemplateClose = this.handleTemplateClose.bind(this);

		this.state = {
			id: null,
			templatename: "",
			status: true,
			submitted: false,
			showmessage: true,
			message: "",
		};
	}

	onChangeTemplateName(e) {
		this.setState({
			templatename: e.target.value,
		});
	}

	// redirectToPage(redirectTo) {
	//   this.setState({
	//     redirect: redirectTo
	//   });
	// }
	handleTemplateClose() {
		this.props.handleTemplateClose();
	}

	handleAddTemplate(e) {
		e.preventDefault();

		this.setState({
			successful: false,
			showmessage: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			this.saveTemplate();
		}
	}

	saveTemplate() {
		//  alert()
		var data = {
			templatename: this.state.templatename,
			projectId: this.props.templateProjectId,
			status: this.state.status,
		};

		// console.log(data);
		TemplateDataService.create(data)
			.then((response) => {
				this.handleTemplateClose();
				this.newTemplate();
				this.notify("success", "template has been created successfully.");
				this.props.retrieveProjects();
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	newTemplate() {
		this.setState({
			id: null,
			templatename: "",
			status: false,
			submitted: false,
		});
	}

	notify(type, message) {
		toast("Template added successfully.", {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		// const { redirect } = this.state;
		// if (redirect) {
		//   return <Redirect to={this.state.redirect} />
		// }
		const { message } = this.state;
		console.log(this.props);
		return (
			<div className="submit-form">
				<Modal
					show={this.props.showtemplate}
					onHide={this.handleTemplateClose}
					backdrop="static"
					keyboard={false}
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title>Add as Template</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.submitted ? (
							<div>
								<h4>You submitted successfully!</h4>
								<button className="btn btn-success" onClick={this.newProject}>
									Add
								</button>
							</div>
						) : (
							<div>
								<Form
									onSubmit={this.handleAddTemplate}
									ref={(c) => {
										this.form = c;
									}}
								>
									<div className="row">
										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="title">Template Name</label>
											<Input
												type="text"
												className="form-control"
												id="templatename"
												value={this.state.templatename}
												onChange={this.onChangeTemplateName}
												name="templatename"
												validations={[required]}
											/>
										</div>
										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="description">Project Name</label>
											<b>{this.props.projectName}</b>
										</div>
									</div>

									{message && (
										<div className="form-group">
											{this.state.showmessage && (
												<div
													className={
														this.state.successful
															? "alert alert-success"
															: "alert alert-danger"
													}
													role="alert"
												>
													{message}
												</div>
											)}
										</div>
									)}
									<button className="btn btn-primary col-sm-3 btn-block m-t-10">
										Add Template
									</button>
									<CheckButton
										style={{ display: "none" }}
										ref={(c) => {
											this.checkBtn = c;
										}}
									/>
								</Form>
							</div>
						)}
					</Modal.Body>
				</Modal>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(AddTemplate);
