import http from "../http-common";
import authHeader from "./auth-header";

class CustomfieldDataService {
	getAll() {
		return http.get("/customfields", { headers: authHeader() });
	}

	get(id) {
		return http.get(`/customfields/${id}`);
	}

	create(data) {
		return http.post("/customfields", data);
	}

	update(id, data) {
		return http.put(`/customfields/${id}`, data);
	}

	delete(id) {
		return http.delete(`/customfields/${id}`);
	}

	deleteAll() {
		return http.delete(`/customfields`);
	}

	findByTitle(customfieldname) {
		return http.get(`/customfields?customfieldname=${customfieldname}`);
	}
}

export default new CustomfieldDataService();
