import http from "../http-common";
import authHeader from "./auth-header";

class RoleDataService {
	getAll() {
		return http.get("/roles", { headers: authHeader() });
	}

	get(id) {
		return http.get(`/roles/${id}`);
	}

	create(data) {
		return http.post("/roles", data);
	}

	update(id, data) {
		return http.put(`/roles/${id}`, data);
	}

	delete(id) {
		return http.delete(`/roles/${id}`);
	}

	deleteAll() {
		return http.delete(`/roles`);
	}
}

export default new RoleDataService();
