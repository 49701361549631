import React, { Component } from "react";
import FolderDataService from "../../services/folder.service";
import ProjectDataService from "../../services/project.service";
import LogDataService from "../../services/log.service";
import AddFolder from "./add-folder.component";
import UploadFile from "./upload-file.component";
import EditFolder from "./edit-folder.component";
import MoveFolder from "./move-folder.component";
import { Redirect } from "react-router-dom";
//import ReactDOM from 'react-dom';
//import SuperTreeview from 'react-super-treeview';
import Tree from "@naisutech/react-tree";
import { FcFolder, FcFile } from "react-icons/fc";
import { connect } from "react-redux";
//import DownloadLink from "react-download-link";
import Table from "react-bootstrap/Table";
//react file viewer
//import logger from 'logging-library';
import FileViewer from "react-file-viewer";
//import { CustomErrorComponent } from 'custom-error';
import {
	RiEditBoxLine,
	RiDeleteBin2Line,
	RiDragDropLine,
} from "react-icons/ri";
import { BiBlock } from "react-icons/bi";
import {
	AiOutlineFileWord,
	AiOutlineFileExcel,
	AiOutlineFilePdf,
	AiOutlineFileImage,
	AiOutlineAudio,
	AiOutlineVideoCamera,
	AiOutlineInfoCircle,
} from "react-icons/ai";
import { GoRepoClone } from "react-icons/go";
import { CgTemplate } from "react-icons/cg";
import AddTemplate from "./../projects/add-template.component";
import EditProject from "./../projects/edit-project.component";

import dateFormat from "dateformat";
import fileDownload from "js-file-download";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown"; //CustomMenu, CustomToggle

import CircularSpinner from "../../helpers/circular";
import { Scrollbars } from "react-custom-scrollbars";

import Slider from "react-slide-out";
import "react-slide-out/lib/index.css";
import { logout } from "../../actions/auth";

const screenfull = require("screenfull");

class FolderList extends Component {
	constructor(props) {
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.retrieveFolders = this.retrieveFolders.bind(this);
		this.refreshList = this.refreshList.bind(this);
		this.setActiveFolder = this.setActiveFolder.bind(this);
		this.addFolderClick = this.addFolderClick.bind(this);
		this.uploadFileClick = this.uploadFileClick.bind(this);
		//this.removeAllFolders = this.removeAllFolders.bind(this);
		this.searchTitle = this.searchTitle.bind(this);
		//   this.nest = this.nest.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleFileClose = this.handleFileClose.bind(this);
		this.handleEditClose = this.handleEditClose.bind(this);
		this.handleMoveClose = this.handleMoveClose.bind(this);
		this.onClickClone = this.onClickClone.bind(this);
		this.onClickTemplate = this.onClickTemplate.bind(this);
		this.handleTemplateClose = this.handleTemplateClose.bind(this);
		this.onClickProjectEdit = this.onClickProjectEdit.bind(this);
		this.retrieveProjects = this.retrieveProjects.bind(this);
		this.handleEditProductClose = this.handleEditProductClose.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onSelectInside = this.onSelectInside.bind(this);
		this.bytesToSize = this.bytesToSize.bind(this);

		//  this.downloadFile = this.downloadFile.bind(this);

		this.state = {
			folders: [],
			subFolders: [],
			currentFolder: null,
			breadcrump: [],
			currentIndex: -1,
			searchTitle: "",
			projectid: "",
			parentid: "",
			showAddFolder: false,
			showUploadFile: false,
			showEditFolder: false,
			showMoveFileFolder: false,
			editFolderId: "",
			editMoveFileFolderId: "",
			projectName: "",
			projectLocation: "",
			projectOFN: "",
			projectVPNumber: "",
			projectBPNumber: "",
			projectIsTemplate: false,
			customoptions: [],
			actionButtons: true,
			fileview: false,
			fileviewpath: "",
			filetype: "",
			show: false,
			showfile: false,
			showedit: false,
			showmove: false,
			templateStatus: false,
			showtemplate: false,
			templateProjectId: "",
			//projectName: "",
			editStatus: false,
			editProjectId: "",
			showeditProject: false,
			loaded: false,
			name: "",
			insideType: "",
			insideFileUrl: "",
			insideProjectId: "",
			isOpen: false,
		};
	}

	componentDidMount() {
		this.retrieveFolders();
		if (screenfull.isEnabled) {
			screenfull.on("change", () => {
				//console.log("Am I fullscreen?", screenfull.isFullscreen ? "Yes" : "No");
			});
		}
	}

	openSlider = () => {
		this.setState({
			isOpen: true,
		});
	};
	closeSlider = () => {
		this.setState({
			isOpen: false,
		});
	};

	onChangeSearchTitle(e) {
		const searchTitle = e.target.value;

		this.setState({
			searchTitle: searchTitle,
		});
	}

	// nest = (items, id = null, link = 'parentid') =>
	// items
	//   .filter(item => item[link] === id)
	//   .map(item => ({ ...item, items: this.nest(items, item.id) }));

	handleClose() {
		this.setState({
			show: false,
		});
	}

	handleFileClose() {
		this.setState({
			showfile: false,
		});
	}

	handleEditClose() {
		this.setState({
			showedit: false,
		});
	}

	handleMoveClose() {
		this.setState({
			showmove: false,
		});
	}

	onSelect = (selectedNode) => {
		//For breadcrump code starts
		let breadcrump = [];
		let breadcrump_index = this.state.breadcrump.findIndex(
			(item) => item.id === selectedNode.id
		);
		if (selectedNode?.parentid) {
			if (selectedNode.parentid === this.state.currentFolder.parentid) {
				breadcrump = this.state.breadcrump.slice(0, -1);
				breadcrump.push(selectedNode);
			} else {
				if (breadcrump_index === -1) {
					breadcrump = this.constructBreadcrumbs(
						this.state.folders,
						selectedNode
					);
				} else {
					breadcrump = this.state.breadcrump.slice(0, breadcrump_index + 1);
				}
			}
		} else {
			breadcrump = [selectedNode];
		}
		//Breadcrump code ends

		if (selectedNode.type !== "file") {
			this.setState({
				currentFolder: selectedNode,
				showAddFolder: false,
				showUploadFile: false,
				showEditFolder: false,
				actionButtons: true,
				fileview: false,
				breadcrump,
			});
		} else {
			let filename = selectedNode?.fileurl?.split("/").pop();

			this.setState({
				actionButtons: false,
				currentFolder: selectedNode,
				fileview: true,
				breadcrump,

				fileviewpath: selectedNode.fileurl.replace(
					"https://peractodms.s3.ap-south-1.amazonaws.com/",
					"https://du3d3qxkngs1f.cloudfront.net/"
				),
				filetype: filename.split(".").pop(),
				loaded: false,
			});
		}
		FolderDataService.getByParent(selectedNode.id)
			.then((response) => {
				//   console.log(response.data);
				//Function to get file size by url Starts
				response.data.map((item) => {
					var url = item.fileurl.replace(
						"https://peractodms.s3.ap-south-1.amazonaws.com/",
						"https://du3d3qxkngs1f.cloudfront.net/"
					);
					return (
						(item.size = this.getFileSize(url)),
						(item.label = item.title),
						(item.parentId = item.parentid),
						(item.items = [])
					);
				});
				//Ends...

				this.setState({
					subFolders: response.data,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};

	//Function to get file size by url Starts
	getFileSize(url) {
		if (url !== "") {
			var fileSize = "";
			var http = new XMLHttpRequest();
			http.open("HEAD", url, false); // false = Synchronous
			http.send(null); // it will stop here until this http request is complete // when we are here, we already have a response, b/c we used Synchronous XHR

			if (http.status === 200) {
				fileSize = http.getResponseHeader("content-length");
			}
			return fileSize;
		}
	}
	//Ends...

	onSelectInside(selectedNode) {
		console.log("selectedNode", selectedNode);
		if (selectedNode.type !== "file") {
			this.setState({
				currentFolder: selectedNode,
				//name: title,
				showAddFolder: false,
				showUploadFile: false,
				showEditFolder: false,
				actionButtons: true,
				fileview: false,
			});
		} else {
			let filename = selectedNode.fileurl?.split("/").pop();

			this.setState({
				actionButtons: false,
				currentFolder: selectedNode,

				fileview: true,

				fileviewpath: selectedNode.fileurl.replace(
					"https://peractodms.s3.ap-south-1.amazonaws.com/",
					"https://du3d3qxkngs1f.cloudfront.net/"
				),
				filetype: filename.split(".").pop(),
				loaded: false,
			});
		}
		FolderDataService.getByParent(selectedNode.id)
			.then((response) => {
				//   console.log(response.data);
				//Function to get file size by url Starts
				response.data.map((item) => {
					var url = item.fileurl.replace(
						"https://peractodms.s3.ap-south-1.amazonaws.com/",
						"https://du3d3qxkngs1f.cloudfront.net/"
					);
					return (item.size = this.getFileSize(url));
				});
				//Ends...

				this.setState({
					subFolders: response.data,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}

	//Breadcrump code starts
	constructBreadcrumbs = (nodeList, _node) => {
		let brdcrmb = [_node];
		brdcrmb = this.makeBreadcrumbs(brdcrmb, nodeList, _node.parentid);
		return brdcrmb;
	};

	makeBreadcrumbs = (breads, nodeList, pid) => {
		let pNode = this.findParentNode(nodeList, pid);
		breads.unshift(pNode);
		if (pNode?.parentid)
			return this.makeBreadcrumbs(breads, nodeList, pNode.parentid);
		else return breads;
	};

	findParentNode = (nodeList, pid) => {
		return nodeList.find((nd) => nd.id === pid);
	};
	//Ends

	getDataFromURL = (url) =>
		new Promise((resolve, reject) => {
			//console.log(url);
			setTimeout(() => {
				fetch(url)
					.then((response) => response.text())
					.then((data) => {
						resolve(data);
					});
			});
		}, 2000);

	retrieveFolders() {
		const projectid = this.props.location.pathname.substring(9);
		// console.log(parent_id);
		this.setState({
			projectid: projectid,
		});

		// get project name and id
		ProjectDataService.get(projectid).then((response) => {
			//console.log(response.data);
			this.setState({
				projectName: response.data.title,
				projectDescription: response.data.description,
				projectLocation: response.data.location.locationname,
				projectOFN: response.data.officefilenumber,
				projectVPNumber: response.data.vpnumber,
				projectBPNumber: response.data.bpnumber,
				projectIsTemplate: response.data.isTemplate,
				customoptions: response.data.customoptions,
			});

			//get folders data
			FolderDataService.getByProject(projectid)
				.then((response) => {
					var res_data = response.data;
					//console.log(res_data);
					// this is creating top root folder
					var root_folder = {
						id: this.state.projectid,
						parentid: null,
						label: this.state.projectName,
						title: this.state.projectName,
						description: this.state.projectDescription,
					};
					res_data.unshift(root_folder);

					// This is for creating lable parentID and itesm required to show tree
					var i;
					for (i = 0; i < res_data.length; i++) {
						res_data[i].label = res_data[i]["title"];
						res_data[i].parentId = res_data[i]["parentid"];
						res_data[i].items = [];
					}

					// this code is for add files under the parent folders
					res_data.filter((element) => {
						if (element.type === "file") {
							res_data.filter(function (item) {
								if (item["id"] === element.parentId) {
									item.items.push(element); // pushing files inside folders
								}
							});
						}
					});

					// code is to remove files from the list
					var b = res_data.filter((e) => e.type === "file");
					b.forEach((f) =>
						res_data.splice(
							res_data.findIndex((e) => e.type === f.type),
							1
						)
					);

					//do not show restrict folder under tree
					if (
						this.props.user.roles !== "ROLE_ADMIN" &&
						!this.props.user.folders_roles.restricted
					) {
						var x = res_data.filter((e) => e.restricted === true);
						x.forEach((f) =>
							res_data.splice(
								res_data.findIndex((e) => e.restricted === f.restricted),
								1
							)
						);
					}

					this.setState({
						folders: res_data,
					});
				})
				// .catch((e) => {
				// 	console.log(e);
				// });
				.catch((error) => {
					console.log(error.response);
					const message =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						message: message,
					});
					if (error.response.status === 401) {
						localStorage.removeItem("user");
						this.props.dispatch(logout());
					}
				});
		});
	}

	refreshList() {
		this.retrieveFolders();
		this.setState({
			currentTutorial: null,
			currentIndex: -1,
		});
	}

	setActiveFolder(folder, index) {
		//  console.log(JSON.stringify(folder));
		this.setState({
			currentFolder: folder,
			currentIndex: index,
			showAddFolder: false,
			showUploadFile: false,
			showEditFolder: false,
		});
	}

	addFolderClick(parent_id) {
		this.setState({
			showAddFolder: true,
			showUploadFile: false,
			showEditFolder: false,
			showMoveFileFolder: false,
			parentid: parent_id,
			show: true,
			showfile: false,
			showedit: false,
			showmove: false,
		});
	}

	uploadFileClick(parent_id) {
		this.setState({
			showAddFolder: false,
			showUploadFile: true,
			showEditFolder: false,
			showMoveFileFolder: false,
			parentid: parent_id,
			show: false,
			showfile: true,
			showedit: false,
			showmove: false,
		});
	}

	onClickEdit(folder_id) {
		// alert();
		this.setState({
			showAddFolder: false,
			showUploadFile: false,
			showEditFolder: true,
			showMoveFileFolder: false,
			parentid: folder_id,
			show: false,
			showfile: false,
			showedit: true,
			showmove: false,
			editFolderId: folder_id,
		});
		// console.log(this.state);
	}

	onClickMove(id) {
		//alert();
		this.setState({
			showAddFolder: false,
			showUploadFile: false,
			showEditFolder: false,
			showMoveFileFolder: true,
			parentid: id,
			show: false,
			showfile: false,
			showedit: false,
			showmove: true,
			editMoveFileFolderId: id,
		});
	}

	onClickDownload() {}

	onClickDelete(id, name) {
		confirmAlert({
			title: `Confirm to Delete ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteFolder(id),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteFolder(id) {
		FolderDataService.delete(id)
			.then((response) => {
				this.retrieveFolders();
				this.onSelect(this.state.currentFolder);
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});

				this.notify("error", message);
			});
	}

	// removeAllFolders() {
	//   FolderDataService.deleteAll()
	//     .then(response => {
	//       console.log(response.data);
	//       this.refreshList();
	//     })
	//     .catch(e => {
	//       console.log(e);
	//     });
	// }

	searchTitle() {
		FolderDataService.findByTitle(this.state.searchTitle)
			.then((response) => {
				this.setState({
					folders: response.data,
				});
				// console.log(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}
	//file viewer error
	onError(e) {
		console.log(e, "error in file-viewer");
	}

	//Clone project
	onClickClone(projectId, name) {
		var data = {
			id: projectId,
		};
		confirmAlert({
			title: `Confirm to Clone a ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.cloneProject(data),
				},
				{
					label: "No",
				},
			],
		});
	}

	cloneProject(projectId) {
		ProjectDataService.cloneProject(projectId)
			.then((response) => {
				//console.log("response: ",response);
				this.onClickProjectEdit(response.data.id);
				this.retrieveProjects();
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
			});
	}

	onClickProjectEdit(projectid) {
		//alert("edit" + projectid);
		this.setState({
			editStatus: true,
			editProjectId: projectid,
			showeditProject: true,
		});
	}

	retrieveProjects() {
		ProjectDataService.getAll()
			.then((response) => {
				var res_data = response.data.filter(function (e) {
					return e.isTemplate === false;
				});
				this.setState({
					projects: res_data,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}

	handleEditProductClose() {
		this.setState({
			showeditProject: false,
		});
	}
	//Clone project ends

	// Template starts
	onClickTemplate(projectId, projectName) {
		confirmAlert({
			title: `Confirm to save ${projectName} as Template`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						this.setState({
							templateStatus: true,
							templateProjectId: projectId,
							showtemplate: true,
							projectName: projectName,
						});
					},
				},
				{
					label: "No",
				},
			],
		});
	}

	handleTemplateClose() {
		this.setState({
			showtemplate: false,
		});
	}
	//Template Ends

	//Download File
	handleDownload = (url, filename, projectid) => {
		url = url.replace(
			"https://peractodms.s3.ap-south-1.amazonaws.com/",
			"https://du3d3qxkngs1f.cloudfront.net/"
		);

		axios
			.get(url, {
				responseType: "blob",
				headers: { "Access-Control-Allow-Origin": "*" },
				//type: "text/plain;charset=utf-8",
			})
			.then((res) => {
				//console.log(res);
				const lastSegment = res.config.url.split(".").pop();

				fileDownload(res.data, `${filename}.${lastSegment}`);
				this.createLog(this.state.id, filename, projectid);
			});
	};

	async createLog(id, filename, projectid) {
		var infodata = {
			activity: "downloaded",
			activityon: "file",
			activityid: projectid, //id,
			activityname: filename,
			userid: this.props.user.id,
			username: this.props.user.username,
			info: "",
		};

		LogDataService.create(infodata)
			.then((response) => {
				//console.log(response);
				this.retrieveProjects();
				this.notify("success", "File has been downloaded successfully.");
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}
	//Download File Ends

	onImageLoaded = () => {
		this.setState({ loaded: true });
	};

	//Calculation of bytes to kb and mb
	bytesToSize(bytes) {
		var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0 || bytes === undefined || bytes === "") return "";
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	}

	toggleFullScreen = () => {
		var elem = document.getElementById("fullscreen");
		//var widthIncrease = document.getElementById("img-view");
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE11 */
			elem.msRequestFullscreen();
		}
	};

	toggleFullScreenFile = () => {
		var elem = document.getElementById("fullscreenfile");
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE11 */
			elem.msRequestFullscreen();
		}
	};

	render() {
		const {
			folders,
			currentFolder,
			fileview,
			fileviewpath,
			subFolders,
			filetype,
			show,
			showfile,
			showedit,
			showmove,
			projectIsTemplate,
			templateStatus,
			showtemplate,
			templateProjectId,
			projectName,
			editStatus,
			//editProjectId,
			showeditProject,
			loaded,
			breadcrump,
		} = this.state;
		const { user: currentUser } = this.props;

		//console.log("currentFolder", currentFolder);

		const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
			<a
				href="_blank"
				ref={ref}
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
			>
				{children}
				<span className="threedots" />
			</a>
		));

		if (!currentUser) {
			return <Redirect to="/login" />;
		}
		return (
			<div className="list">
				<div className="row">
					<div className="col-md-2">
						<h4>
							{folders[0]?.title.length > 16
								? folders[0]?.title.substring(0, 15) + "..."
								: folders[0]?.title}
						</h4>{" "}
						<hr />
						<Scrollbars style={{ height: "80vh" }}>
							<Tree
								nodes={folders}
								onSelect={this.onSelect}
								theme={"light"}
								size={"full"}
								grow
								showEmptyItems
							/>
						</Scrollbars>
					</div>
					<div className="col-md-10">
						<div className="row border-btn-bottom">
							{currentFolder ? (
								<div className="col-md-8">
									<div className="breadcrump-manual float-left d-flex">
										{breadcrump?.length ? (
											breadcrump?.map((item, index, arr) => (
												<h4
													className="breadcrump-header"
													key={index}
													onClick={
														index + 1 < breadcrump.length
															? () => {
																	this.onSelect(item);
															  }
															: void 0
													}
												>
													<span className="breadcrump-title">{item.title}</span>{" "}
													<span>{index !== arr.length - 1 ? "> " : ""}</span>
												</h4>
											))
										) : (
											<h4>{currentFolder.title}</h4>
										)}
									</div>
									<div className="action_buttons float-right">
										{this.state.actionButtons &&
										currentUser.folders_roles.add &&
										!projectIsTemplate ? (
											<button
												className="btn btn-sm btn-primary m-r-10"
												onClick={() => this.addFolderClick(currentFolder.id)}
											>
												Add Folder
											</button>
										) : (
											""
										)}
										{currentFolder.type === "file"
											? [
													currentUser.folders_roles.download ? (
														<button
															onClick={() => {
																this.handleDownload(
																	currentFolder.fileurl,
																	currentFolder.title,
																	currentFolder.projectid
																);
															}}
															className="btn  btn-sm btn-primary"
														>
															Download
														</button>
													) : (
														""
													),
											  ]
											: ""}

										{this.state.actionButtons &&
										currentUser.folders_roles.add &&
										!projectIsTemplate ? (
											<button
												className="btn  btn-sm btn-primary"
												onClick={() => this.uploadFileClick(currentFolder.id)}
											>
												Upload File
											</button>
										) : (
											""
										)}
									</div>
									<div className="clear-both"></div>
								</div>
							) : (
								<div className="">
									{" "}
									<h4>{projectName}</h4>
								</div>
							)}
							{/* button code ends  */}

							<div className="col-md-4 col-xs-12 right-folder-details">
								{folders ? (
									<>
										{currentUser && currentUser.project_roles.add ? (
											<button
												className="btn btn-sm btn-success ml-2 mr-2"
												onClick={() =>
													this.onClickClone(folders[0]?.id, folders[0]?.title)
												}
											>
												<GoRepoClone title="clone" size={14} /> Clone
											</button>
										) : (
											""
										)}
										{currentUser && currentUser.roles === "ROLE_ADMIN" ? (
											<button
												className="btn btn-sm btn-secondary ml-2 mr-2"
												onClick={() =>
													this.onClickTemplate(
														folders[0]?.id,
														folders[0]?.title
													)
												}
											>
												<CgTemplate title="template" size={14} /> Template
											</button>
										) : (
											<></>
										)}
									</>
								) : (
									<></>
								)}
								<button
									href="#"
									onClick={this.openSlider}
									className="btn btn-sm btn-primary btn-info"
								>
									<AiOutlineInfoCircle title="info" size={20} /> Info
								</button>
							</div>
						</div>

						{fileview ? (
							[
								filetype === "jpg" ||
								filetype === "JPG" ||
								filetype === "jpeg" ||
								filetype === "JPEG" ||
								filetype === "png" ||
								filetype === "PNG" ? (
									<div className="image-container">
										<button
											className="btn  btn-sm btn-primary mb-3"
											onClick={this.toggleFullScreen}
										>
											Full Screen
										</button>

										<img
											key={fileviewpath}
											src={fileviewpath}
											alt={fileviewpath}
											className="img-view responsive"
											id="fullscreen"
											onLoad={this.onImageLoaded}
										></img>
										{!loaded && (
											<div className="image-container-overlay">
												<CircularSpinner />
											</div>
										)}
									</div>
								) : (
									<>
										<button
											className="btn  btn-sm btn-primary mb-3"
											onClick={this.toggleFullScreenFile}
										>
											Full Screen
										</button>
										<span id="fullscreenfile">
											<FileViewer
												key={fileviewpath}
												fileType={filetype}
												filePath={fileviewpath}
												onError={this.onError}
											/>
										</span>
									</>
								),
							]
						) : (
							<>
								<Table
									striped
									bordered
									hover
									responsive
									className="product-list-table"
								>
									<thead>
										<tr>
											<th className="hide-desktop"></th>
											<th>NAME</th>
											<th>File Size</th>
											<th>OWNER</th>
											<th>UPDATED ON</th>
											<th className="hide-mobile">ACTIONS</th>
										</tr>
									</thead>
									<tbody>
										{subFolders &&
											subFolders.map((folder, index) => [
												folder.restricted &&
												currentUser.roles !== "ROLE_ADMIN" &&
												!currentUser.folders_roles.restricted ? (
													""
												) : (
													<tr key={index}>
														<td className="hide-desktop">
															<Dropdown className="hide-desktop">
																<Dropdown.Toggle
																	id="dropdown-custom-components"
																	as={CustomToggle}
																></Dropdown.Toggle>

																<Dropdown.Menu size="sm" title="">
																	{folder.type === "folder" ? (
																		<>
																			{currentUser &&
																			currentUser.folders_roles.move ? (
																				<Dropdown.Item
																					eventKey="1"
																					onClick={() =>
																						this.onClickMove(folder.id)
																					}
																				>
																					<RiDragDropLine size={14} /> Move
																				</Dropdown.Item>
																			) : (
																				""
																			)}
																			{currentUser &&
																			currentUser.folders_roles.edit ? (
																				<Dropdown.Item
																					eventKey="1"
																					onClick={() =>
																						this.onClickEdit(folder.id)
																					}
																				>
																					<RiEditBoxLine size={14} /> Edit
																				</Dropdown.Item>
																			) : (
																				""
																			)}
																			{currentUser &&
																			currentUser.folders_roles.delete ? (
																				<Dropdown.Item
																					eventKey="1"
																					onClick={() =>
																						this.onClickDelete(
																							folder.id,
																							folder.title
																						)
																					}
																				>
																					<RiDeleteBin2Line size={14} /> Delete
																				</Dropdown.Item>
																			) : (
																				""
																			)}
																		</>
																	) : (
																		<>
																			{currentUser &&
																			currentUser.folders_roles.move ? (
																				<Dropdown.Item
																					eventKey="1"
																					onClick={() =>
																						this.onClickMove(folder.id)
																					}
																				>
																					<RiDragDropLine size={14} /> Move
																				</Dropdown.Item>
																			) : (
																				""
																			)}
																			{currentUser &&
																			currentUser.folders_roles.delete ? (
																				<Dropdown.Item
																					eventKey="1"
																					onClick={() =>
																						this.onClickDelete(
																							folder.id,
																							folder.title
																						)
																					}
																				>
																					<RiDeleteBin2Line size={14} /> Delete
																				</Dropdown.Item>
																			) : (
																				""
																			)}
																		</>
																	)}
																</Dropdown.Menu>
															</Dropdown>
														</td>
														<td
															onClick={(event) => this.onSelect(folder)}
															className="cursor_pointer"
														>
															{folder.type === "folder" ? (
																<FcFolder size={20} />
															) : (
																(() => {
																	let filename = folder?.fileurl
																		?.split("/")
																		.pop();
																	let fileexttype = filename?.split(".").pop();

																	if (fileexttype === "pdf")
																		return <AiOutlineFilePdf size={20} />;
																	else if (
																		fileexttype === "jpg" ||
																		fileexttype === "JPG" ||
																		fileexttype === "png" ||
																		fileexttype === "PNG" ||
																		fileexttype === "jpeg" ||
																		fileexttype === "JPEG"
																	)
																		return <AiOutlineFileImage size={20} />;
																	else if (fileexttype === "docx")
																		return <AiOutlineFileWord size={20} />;
																	else if (fileexttype === "xlsx")
																		return <AiOutlineFileExcel size={20} />;
																	else if (fileexttype === "mp3")
																		return <AiOutlineAudio size={20} />;
																	else if (fileexttype === "mp4")
																		return <AiOutlineVideoCamera size={20} />;
																	else return <FcFile size={20} />;
																})()
															)}{" "}
															{folder.title}
															{folder.restricted ? (
																<div className="restricted">
																	<BiBlock size={14} />
																</div>
															) : (
																""
															)}
														</td>
														<td>{this.bytesToSize(folder.size)}</td>
														<td>
															{!folder.createdBy
																? "admin"
																: folder.createdBy.username}
														</td>

														<td>
															{dateFormat(folder.updatedAt, "dS mmmm yyyy")}
														</td>
														<td className="hide-mobile">
															<div className="action-button-grid m-t-0 hide-mobile">
																{folder.type === "folder" ? (
																	<div>
																		{currentUser &&
																		currentUser.folders_roles.move ? (
																			<button
																				className="btn btn-sm btn-success mr-2"
																				onClick={() =>
																					this.onClickMove(folder.id)
																				}
																			>
																				<RiDragDropLine size={14} /> Move
																			</button>
																		) : (
																			""
																		)}

																		{currentUser &&
																		currentUser.folders_roles.edit ? (
																			<button
																				className="btn btn-sm btn-warning text-white mr-2"
																				onClick={() =>
																					this.onClickEdit(folder.id)
																				}
																			>
																				<RiEditBoxLine size={14} /> Edit
																			</button>
																		) : (
																			""
																		)}
																		{currentUser &&
																		currentUser.folders_roles.delete ? (
																			<button
																				className="btn btn-sm btn-danger mr-2"
																				onClick={() =>
																					this.onClickDelete(
																						folder.id,
																						folder.title
																					)
																				}
																			>
																				<RiDeleteBin2Line size={14} /> Delete
																			</button>
																		) : (
																			""
																		)}
																	</div>
																) : (
																	<div>
																		{currentUser &&
																		currentUser.folders_roles.move ? (
																			<button
																				className="btn btn-sm btn-success mr-2"
																				onClick={() =>
																					this.onClickMove(folder.id)
																				}
																			>
																				<RiDragDropLine size={14} /> Move
																			</button>
																		) : (
																			""
																		)}
																		{currentUser &&
																		currentUser.folders_roles.delete ? (
																			<button
																				className="btn btn-sm btn-danger mr-2"
																				onClick={() =>
																					this.onClickDelete(
																						folder.id,
																						folder.title
																					)
																				}
																			>
																				<RiDeleteBin2Line size={14} /> Delete
																			</button>
																		) : (
																			""
																		)}
																	</div>
																)}
															</div>
														</td>
													</tr>
												),
											])}
									</tbody>
								</Table>
							</>
						)}
					</div>
					<div className="col-md-3 m-b-3">
						{this.state.showAddFolder ? (
							<AddFolder
								show={show}
								handleClose={this.handleClose}
								parentid={this.state.parentid}
								projectid={this.state.projectid}
								retrieveFolders={this.retrieveFolders}
								onSelect={this.onSelect}
								currentFolder={this.state.currentFolder}
								breadcrump={breadcrump}
							/>
						) : (
							""
						)}
						{this.state.showUploadFile ? (
							<UploadFile
								showfile={showfile}
								handleFileClose={this.handleFileClose}
								parentid={this.state.parentid}
								projectid={this.state.projectid}
								retrieveFolders={this.retrieveFolders}
								onSelect={this.onSelect}
								currentFolder={this.state.currentFolder}
								breadcrump={breadcrump}
							/>
						) : (
							""
						)}
						{this.state.showEditFolder ? (
							<EditFolder
								showedit={showedit}
								handleEditClose={this.handleEditClose}
								parentid={this.state.parentid}
								projectid={this.state.projectid}
								retrieveFolders={this.retrieveFolders}
								onSelect={this.onSelect}
								currentFolder={this.state.currentFolder}
								key={this.state.editFolderId}
								editFolderId={this.state.editFolderId}
							/>
						) : (
							""
						)}
						{this.state.showMoveFileFolder ? (
							<MoveFolder
								showmove={showmove}
								handleMoveClose={this.handleMoveClose}
								parentid={this.state.parentid}
								projectid={this.state.projectid}
								retrieveFolders={this.retrieveFolders}
								onSelect={this.onSelect}
								currentFolder={this.state.currentFolder}
								key={this.state.editMoveFileFolderId}
								editMoveFileFolderId={this.state.editMoveFileFolderId}
							/>
						) : (
							""
						)}
						{templateStatus ? (
							<AddTemplate
								templateProjectId={templateProjectId}
								projectName={projectName}
								showtemplate={showtemplate}
								handleTemplateClose={this.handleTemplateClose}
								retrieveProjects={this.retrieveProjects}
							/>
						) : (
							""
						)}
						{editStatus ? (
							<EditProject
								showedit={showeditProject}
								handleEditClose={this.handleEditProductClose}
								key={this.state.editProjectId}
								retrieveProjects={this.retrieveProjects}
								editProjectId={this.state.editProjectId}
							/>
						) : (
							""
						)}
					</div>
					<div>
						{currentFolder ? (
							<div>
								<Slider
									title={currentFolder.title}
									footer={
										<div style={{ padding: "15px" }}>
											<button
												href="#"
												onClick={this.closeSlider}
												className="btn btn-sm btn-danger"
											>
												Close Slider
											</button>
										</div>
									}
									isOpen={this.state.isOpen}
									onOutsideClick={this.closeSlider}
								>
									<div className="slider-drawer-content">
										<div>
											<strong>Title: </strong>
											{currentFolder.title}
										</div>
										{/* if this is file */}

										{(() => {
											if (currentFolder.type === "file") {
												return <div></div>;
											} else if (currentFolder.type === "folder") {
												return <div></div>;
											} else {
												return (
													<div>
														<div>
															<br />
															<strong>Description: </strong>
															{currentFolder.description}
														</div>
														<hr />
														<div>
															<h6>Meta Data</h6> <hr />
															<Table bordered>
																<tbody>
																	<tr>
																		<td className="td-bg">
																			<b>Location: </b>
																		</td>
																		<td>{this.state.projectLocation}</td>
																	</tr>
																	<tr>
																		<td className="td-bg">
																			<b>File Number: </b>
																		</td>
																		<td>{this.state.projectOFN}</td>
																	</tr>
																	<tr>
																		<td className="td-bg">
																			<b>VP Number: </b>
																		</td>
																		<td>{this.state.projectVPNumber}</td>
																	</tr>
																	<tr>
																		<td className="td-bg">
																			<b>BP Number: </b>
																		</td>
																		<td>{this.state.projectBPNumber}</td>
																	</tr>
																</tbody>
															</Table>
														</div>
														<div>
															<h6>CustomField Data</h6> <hr />
															<Table bordered>
																<tbody>
																	{this.state.customoptions &&
																	this.state.customoptions.length
																		? this.state.customoptions.map(
																				(items, index) => {
																					if (Array.isArray(items.value)) {
																						return (
																							<tr key={index}>
																								<td className="td-bg">
																									<b>{items.label}</b>
																								</td>
																								<td>
																									{items.value.join(", ")}
																								</td>
																							</tr>
																						);
																					} else {
																						return (
																							<tr key={index}>
																								<td className="td-bg">
																									<b>{items.label}</b>
																								</td>
																								<td>{items.value}</td>
																							</tr>
																						);
																					}
																				}
																		  )
																		: ""}
																</tbody>
															</Table>
														</div>
													</div>
												);
											}
										})()}

										<hr />
									</div>
								</Slider>
							</div>
						) : (
							<div></div>
						)}
					</div>
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(FolderList);
