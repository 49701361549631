import React, { Component } from "react";
import LogDataService from "../../services/log.service";
import Table from "react-bootstrap/Table";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Link } from "react-router-dom";

// import { RiInformationLine, RiDeleteBin2Line } from "react-icons/ri";
// import { IoIosArrowDropright } from "react-icons/io";
import { Redirect } from "react-router-dom";

//import Treeview from "../treeview/treeview.component";

import { connect } from "react-redux";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { logout } from "../../actions/auth";

class Log extends Component {
	constructor(props) {
		super(props);

		this.retrieveLogs = this.retrieveLogs.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);

		this.state = {
			logs: [],
			showadd: false,
			showedit: false,
			editStatus: false,
			//projectid:""
			offset: 0,
			perPage: 10,
			currentPage: 0,
		};
	}

	componentDidMount() {
		this.retrieveLogs();
	}

	retrieveLogs() {
		// get Logs Data
		LogDataService.getAll()
			.then((response) => {
				// this.setState({
				//   logs: response.data
				// });
				const logs = response.data.sort(
					(a, b) =>
						Date.parse(new Date(b.createdAt.split("/").reverse().join("-"))) -
						Date.parse(new Date(a.createdAt.split("/").reverse().join("-")))
				);
				const slice = logs.slice(
					this.state.offset,
					this.state.offset + this.state.perPage
				);
				const postData = slice.map((log, index) => (
					<tr key={index}>
						<td>{log?.username ? log?.username : ""}</td>
						<td>{log?.activity ? log?.activity : ""}</td>
						<td>{log?.activityon ? log?.activityon : ""}</td>
						<td>{log?.activityid?.title ? log?.activityid?.title : ""}</td>
						<td>
							{log?.createdAt
								? dateFormat(log?.createdAt, "dS mmmm yyyy HH:mm:ss")
								: ""}
						</td>
					</tr>
				));

				this.setState({
					pageCount: Math.ceil(logs.length / this.state.perPage),
					postData,
				});
			})
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
				}
			});
	}

	handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * this.state.perPage;

		this.setState(
			{
				currentPage: selectedPage,
				offset: offset,
			},
			() => {
				this.retrieveLogs();
			}
		);
	};

	handleClose() {
		this.setState({
			showadd: false,
		});
	}

	handleEditClose() {
		this.setState({
			showedit: false,
		});
	}

	// onClickShowTree(projectId){
	//   this.setState({
	//     projectid: projectId
	//   });
	// }

	onClickDelete(templateid) {
		confirmAlert({
			title: "Confirm to Delete",
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteTemplate(templateid),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteTemplate(templateid) {
		LogDataService.delete(templateid)
			.then((response) => {
				this.retrieveLogs();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { postData } = this.state; //logs
		const { user: currentUser } = this.props;
		//console.log(currentUser);
		if (!currentUser || currentUser.roles !== "ROLE_ADMIN") {
			return <Redirect to="/login" />;
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h4>Logs List</h4>
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th>User Name</th>
									<th>Activity</th>
									<th>Activity On</th>
									<th>Project Name</th>
									<th>Activity Date</th>
									{/* <th>Actions</th> */}
								</tr>
							</thead>
							<tbody>
								{
									postData && <>{postData}</>
									// logs.map((log, index) => (
									//     <tr key={index}>
									//         <td>{log?.username ? log?.username : ""}</td>
									//         <td>{log?.activity ? log?.activity : ""}</td>
									//         <td>{log?.activityname ? log?.activityname : ""}</td>
									//         <td>{log?.activityid?.title ? log?.activityid?.title : ""}</td>
									//         <td>{log?.createdAt ? dateFormat(log?.createdAt, "dS mmmm yyyy") : ""}</td>
									//         {/* {dateFormat(project.updatedAt, "dS mmmm yyyy")} */}

									//         {/* <td>
									//           <div className="action-button-grid m-t-0">
									//           <Link
									//             to={"/folders/" + log.projectId._id}  className=" ">
									//                 <RiInformationLine size={22} />
									//             </Link>
									//             <IoIosArrowDropright size={22} onClick={() => this.onClickShowTree(log.projectId._id)} />
									//             <RiDeleteBin2Line size={22} onClick={() => this.onClickDelete(log._id)} />
									//           </div>
									//         </td> */}
									//     </tr>
									// ))
								}
							</tbody>
						</Table>
						<ReactPaginate
							previousLabel={"prev"}
							nextLabel={"next"}
							breakLabel={"..."}
							breakClassName={"break-me"}
							pageCount={this.state.pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={this.handlePageClick}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>
					</div>
					{/* <div className="col-sm-4">
                <Treeview
                      key={this.state.projectid}
                      projectid={this.state.projectid}
                   />
            </div> */}
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Log);
