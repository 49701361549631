import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
//import  { Redirect } from 'react-router-dom'

import AddNewProject from "./add-new-project.component";
import CloneProject from "./clone-project.component";
import CreateFromTemplate from "./create-from-template.component";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { connect } from "react-redux";


class AddProject extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      showmessage:true,
      message:"",
    };
  }

  
  render() {
    return (
      <div className="submit-form">
        <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.state.submitted ? (
                <div>
                </div>
              ) : (
                <Tabs>
                  <TabList>
                    <Tab>New</Tab>
                    <Tab>Template</Tab>
                    <Tab>Clone</Tab>
                  </TabList>

                  <TabPanel>
                    <AddNewProject notify={this.props.notify} handleClose={this.props.handleClose} retrieveProjects={this.props.retrieveProjects} />
                  </TabPanel>
                  <TabPanel>
                    <CreateFromTemplate notify={this.props.notify} handleClose={this.props.handleClose} onClickEdit={this.props.onClickEdit} retrieveProjects={this.props.retrieveProjects} />
                  </TabPanel>
                  <TabPanel>
                    <CloneProject projects={this.props.projects} notify={this.props.notify} handleClose={this.props.handleClose} onClickEdit={this.props.onClickEdit} retrieveProjects={this.props.retrieveProjects} />
                  </TabPanel>
                </Tabs>
             )}
          </Modal.Body>
        </Modal>
       
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(AddProject);

