import React, { Component } from "react";
import RoleDataService from "../../services/role.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
//import  { Redirect } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class EditRole extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.handleChangePAdd = this.handleChangePAdd.bind(this);
    this.handleChangePEdit = this.handleChangePEdit.bind(this);
    this.handleChangePDelete = this.handleChangePDelete.bind(this);
    this.handleChangeFAdd = this.handleChangeFAdd.bind(this);
    this.handleChangeFMove = this.handleChangeFMove.bind(this);
    this.handleChangeFEdit = this.handleChangeFEdit.bind(this);
    this.handleChangeFDownload = this.handleChangeFDownload.bind(this);
    this.handleChangeFRestricted = this.handleChangeFRestricted.bind(this);
    this.handleChangeFDelete = this.handleChangeFDelete.bind(this);
    this.handleUpdateRole = this.handleUpdateRole.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
   
    this.state = {
      id: null,
      name: "",
      submitted: false,
      showmessage: true,
      message:"",
      pAddChecked: false,
      pEditChecked: false,
      pDeleteChecked: false,
      fAddChecked: false,
      fMoveChecked: false,
      fEditChecked: false,
      fDownloadChecked: false,
      fRestrictedChecked: false,
      fDeleteChecked: false
    };
  }

  componentDidMount() {
    this.getRole();
  }

  getRole(){
    RoleDataService.get(this.props.editRoleId)
     .then(response => {
       this.setState({
         name: response.data.name,
         pAddChecked: response.data.projects.add, 
         pEditChecked: response.data.projects.edit,
         pDeleteChecked: response.data.projects.delete,
         fAddChecked: response.data.folders.add, 
         fMoveChecked: response.data.folders.move,
         fEditChecked: response.data.folders.edit,
         fDownloadChecked: response.data.folders.download,
         fRestrictedChecked: response.data.folders.restricted,
         fDeleteChecked: response.data.folders.delete,
       });

     })
     .catch(e => {
       console.log(e);
     });
   }
 

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleChangePAdd(e) {
    this.setState({ pAddChecked: e.target.checked });
  }

  handleChangePEdit(e) {
    this.setState({ pEditChecked: e.target.checked });
  }
  handleChangePDelete(e) {
    this.setState({ pDeleteChecked: e.target.checked });
  }
  handleChangeFAdd(e) {
    this.setState({ fAddChecked: e.target.checked });
  }
  handleChangeFMove(e) {
    this.setState({ fMoveChecked: e.target.checked });
  }
  handleChangeFEdit(e) {
    this.setState({ fEditChecked: e.target.checked });
  }
  handleChangeFDownload(e) {
    this.setState({ fDownloadChecked: e.target.checked });
  }
  handleChangeFRestricted(e) {
    this.setState({ fRestrictedChecked: e.target.checked });
  }
  handleChangeFDelete(e) {
    this.setState({ fDeleteChecked: e.target.checked });
  }

  handleEditClose() {
    this.props.handleEditClose();
  }

  handleUpdateRole(e) {
    e.preventDefault();

    this.setState({
        successful: false,
        showmessage:true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.updateRole();
    }
  }

  updateRole() {
  //  alert()
    var data = {
      name: this.state.name,
      projects:{
        add: this.state.pAddChecked,
        edit: this.state.pEditChecked,
        delete: this.state.pDeleteChecked,
      },
      folders:{
        add: this.state.fAddChecked,
        move: this.state.fMoveChecked,
        edit: this.state.fEditChecked,
        download: this.state.fDownloadChecked,
        restricted: this.state.fRestrictedChecked,
        delete: this.state.fDeleteChecked,
      }
    };
    
    // console.log(this.state.redirect);
    RoleDataService.update(this.props.editRoleId,data)
    .then(response => {
      this.handleEditClose();
      this.notify("success",response.data.message);
      this.props.retrieveRoles();
    })
    .catch(error => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
    
      this.setState({
        message: message
      });
      this.notify("error",message);
    });
    
  }

  notify(type,message){ 
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        type:type
      }
    );
  }
  
  render() {
    const { message } = this.state;
    return (
      <div className="submit-form">
         <Modal
          show={this.props.showedit}
          onHide={this.handleEditClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.submitted ? (
              <div>
              </div>
            ) : (
              <div>
                <Form
                    onSubmit={this.handleUpdateRole}
                    ref={(c) => {
                        this.form = c;
                    }}
                >
                <div className="form-group m-b-10">
                  <label htmlFor="name">Role Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    id="name"
                    value={this.state.name}
                    onChange={this.onChangeName}
                    name="name"
                    validations={[required]}
                  />
                </div>

                <div className="form-group m-b-10">
                  <label htmlFor="name">Project Access</label>
                  <div className="row p-20 m-l-5 m-r-5 border-1">
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        id="padd"
                        checked={this.state.pAddChecked}
                        onChange={this.handleChangePAdd}
                        name="padd"
                      /> Add
                    </div>
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        id="pedit"
                        checked={this.state.pEditChecked}
                        onChange={this.handleChangePEdit}
                        name="pedit"
                      /> Edit
                    </div>
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        id="pdelete"
                        checked={this.state.pDeleteChecked}
                        onChange={this.handleChangePDelete}
                        name="pdelete"
                      /> Delete
                    </div>
                  </div>
                </div>

                <div className="form-group m-b-10">
                  <label htmlFor="name">Folders Access</label>
                  <div className="row p-20 m-l-5 m-r-5 border-1">
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        id="name"
                        checked={this.state.fAddChecked}
                        onChange={this.handleChangeFAdd}
                        name="fadd"
                      /> Add
                    </div>
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        id="fmove"
                        checked={this.state.fMoveChecked}
                        onChange={this.handleChangeFMove}
                        name="fmove"
                      /> Move
                    </div>
                    <div className="col-sm-2">
                      <input
                        type="checkbox"
                        id="fedit"
                        checked={this.state.fEditChecked}
                        onChange={this.handleChangeFEdit}
                        name="fedit"
                      /> Edit
                    </div>
                    <div className="col-sm-3">
                      <input
                        type="checkbox"
                        id="fdownload"
                        checked={this.state.fDownloadChecked}
                        onChange={this.handleChangeFDownload}
                        name="fdownload"
                      /> Download
                    </div>
                    <div className="col-sm-3">
                      <input
                        type="checkbox"
                        id="fdownload"
                        checked={this.state.fRestrictedChecked}
                        onChange={this.handleChangeFRestricted}
                        name="fdownload"
                      /> Restricted
                    </div>
                    <div className="col-sm-3">
                      <input
                        type="checkbox"
                        id="fdelete"
                        checked={this.state.fDeleteChecked}
                        onChange={this.handleChangeFDelete}
                        name="fdelete"
                      /> Delete
                    </div>
                  </div>
                </div>

                {message && (
                  <div className="form-group">
                    {this.state.showmessage && (
                  <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                  </div>
                    )}
                  </div>
                
                )}
                <hr />
                <button className="btn btn-sm btn-primary col-sm-2 m-t-10 btn-block">Update Role</button>
                  {/* <button onClick={this.saveProject} className="btn btn-success m-t-10">
                    Submit
                  </button> */}
                  <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                      this.checkBtn = c;
                      }}
                  />
                </Form>
              </div>
            )}
           </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(EditRole);
