import React, { Component } from "react";

import ProjectDataService from "../../services/project.service";

import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";

import { connect } from "react-redux";
import Treeview from "../treeview/treeview.component";

// const required = (value) => {
//     if (!value) {
//       return (
//         <div className="alert alert-danger" role="alert">
//           This field is required!
//         </div>
//       );
//     }
//   };

class CloneProject extends Component {
	constructor(props) {
		super(props);
		this.handleCloneProject = this.handleCloneProject.bind(this);
		this.onChangeProject = this.onChangeProject.bind(this);

		this.state = {
			id: null,
			project: "",
			submitted: false,
			showmessage: true,
			message: "",
		};
	}

	onChangeProject(e) {
		this.setState({
			project: e.target.value,
		});

		console.log(this.state);
	}

	handleCloneProject(e) {
		e.preventDefault();

		this.setState({
			successful: false,
			showmessage: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			var projectId = this.state.project;
			this.cloneProject(projectId);
		}
	}

	cloneProject(projectId) {
		var data = {
			id: projectId,
		};

		ProjectDataService.cloneProject(data)
			.then((response) => {
				//  console.log("..................."+JSON.stringify(response))
				this.props.notify("success", response.data.message);
				this.props.retrieveProjects();
				this.props.handleClose();
				this.props.onClickEdit(response.data.id);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.props.notify("error", message);
			});
	}

	render() {
		const { message } = this.state;
		return (
			<div>
				<Form
					onSubmit={this.handleCloneProject}
					ref={(c) => {
						this.form = c;
					}}
				>
					<div className="form-group m-b-10">
						<label htmlFor="project">Choose From Projects</label>
						<Select
							className="form-control m-t-10"
							id="project"
							name="project"
							value={this.state.project}
							onChange={this.onChangeProject}
						>
							<option value="">Select Project</option>
							{this.props.projects.map((option) => (
								<option value={option.id}>{option.title}</option>
							))}
						</Select>
					</div>
					<Treeview key={this.state.project} projectid={this.state.project} />

					{message && (
						<div className="form-group">
							{this.state.showmessage && (
								<div
									className={
										this.state.successful
											? "alert alert-success"
											: "alert alert-danger"
									}
									role="alert"
								>
									{message}
								</div>
							)}
						</div>
					)}
					<button className="btn btn-primary col-sm-3 btn-block m-t-10">
						Clone Project
					</button>
					{/* <button onClick={this.saveProject} className="btn btn-success m-t-10">
                        Submit
                      </button> */}
					<CheckButton
						style={{ display: "none" }}
						ref={(c) => {
							this.checkBtn = c;
						}}
					/>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(CloneProject);
