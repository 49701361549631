import http from "../http-common";
import authHeader from './auth-header';

class AuthService {
  login(username, password) {
    return http
      .post("/auth/signin", { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, fullname, password, role) {
    return http.post("/auth/signup", {
      username,
      fullname,
      password,
      role,
    }, { headers: authHeader() });
  }
}

export default new AuthService();