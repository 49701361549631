import React, { Component } from "react";
import ProjectDataService from "../../services/project.service";
import TemplateDataService from "../../services/template.service";
import { Link, Redirect } from "react-router-dom";
import Table from "react-bootstrap/Table";
import AddProject from "./add-project.component";
import AddTemplate from "./add-template.component";
import EditProject from "./edit-project.component";
import { FcFolder } from "react-icons/fc";
import { connect } from "react-redux";
import { RiEditBoxLine, RiDeleteBin2Line } from "react-icons/ri";
import { GoRepoClone } from "react-icons/go";
import { CgTemplate } from "react-icons/cg";
import Dropdown from "react-bootstrap/Dropdown"; //CustomMenu, CustomToggle

import dateFormat from "dateformat";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { logout } from "../../actions/auth";

class ProjectList extends Component {
	constructor(props) {
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.retrieveProjects = this.retrieveProjects.bind(this);
		//   this.refreshList = this.refreshList.bind(this);
		//   this.setActiveFolder = this.setActiveFolder.bind(this);
		//   this.removeAllFolders = this.removeAllFolders.bind(this);
		this.searchTitle = this.searchTitle.bind(this);
		this.onClickEdit = this.onClickEdit.bind(this);
		this.onClickClone = this.onClickClone.bind(this);
		this.onClickTemplate = this.onClickTemplate.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleEditClose = this.handleEditClose.bind(this);
		this.handleTemplateClose = this.handleTemplateClose.bind(this);
		this.addProjectClick = this.addProjectClick.bind(this);

		this.state = {
			projects: [],
			editProject: {},
			//    currentFolder: null,
			//    currentIndex: -1,
			searchTitle: "",
			editStatus: false,
			templateStatus: false,
			editProjectId: "",
			show: false,
			showedit: false,
			showtemplate: false,
			templateProjectId: "",
			projectName: "",
		};
	}

	componentDidMount() {
		this.retrieveProjects();
	}

	onChangeSearchTitle(e) {
		const searchTitle = e.target.value;

		this.setState({
			searchTitle: searchTitle,
		});
	}

	retrieveProjects() {
		ProjectDataService.getAll()
			.then((response) => {
				//console.log(response.data);
				var res_data = response.data.filter(function (e) {
					return e.isTemplate === false;
				});

				this.setState({
					projects: res_data,
				});
				//console.log(response.data);
			})
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
					//return <Redirect to="/login" />;
				}
			});
	}

	handleClose() {
		this.setState({
			show: false,
		});
	}

	handleEditClose() {
		this.setState({
			showedit: false,
		});
	}

	handleTemplateClose() {
		this.setState({
			showtemplate: false,
		});
	}

	addProjectClick() {
		this.setState({
			show: true,
		});
	}

	searchTitle() {
		var str = this.state.searchTitle.toLowerCase();
		const filter = this.state?.projects?.filter((obj) => {
			//console.log("obj", obj);
			if (obj?.title?.toString().toLowerCase().includes(str)) {
				return true;
			} else if (obj?.bpnumber?.toString().toLowerCase().includes(str)) {
				return true;
			} else if (obj?.vpnumber?.toString().toLowerCase().includes(str)) {
				return true;
			} else if (
				obj?.officefilenumber?.toString().toLowerCase().includes(str)
			) {
				return true;
			} else if (
				obj?.customoptions?.find((item) =>
					item?.value?.toString().toLowerCase().includes(str)
				)
			) {
				return true;
			} else if (
				obj?.location?.locationname?.toString().toLowerCase().includes(str)
			) {
				return true;
			} else {
				return false;
			}
		});
		this.setState({
			filterProjects: filter,
		});
		//console.log("filter", filter);
	}

	onClickEdit(projectid) {
		this.setState({
			editStatus: true,
			editProjectId: projectid,
			showedit: true,
		});
	}

	onClickClone(projectId, name) {
		//alert("edit" + projectid);
		var data = {
			id: projectId,
		};
		confirmAlert({
			title: `Confirm to Clone ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.cloneProject(data),
				},
				{
					label: "No",
				},
			],
		});
	}

	cloneProject(projectId) {
		ProjectDataService.cloneProject(projectId)
			.then((response) => {
				this.retrieveProjects();
				this.onClickEdit(response.data.id);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
			});
	}

	onClickTemplate(projectId, projectName) {
		//alert("edit" + projectid);

		confirmAlert({
			title: `Confirm to save ${projectName} as Template`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						this.setState({
							templateStatus: true,
							templateProjectId: projectId,
							showtemplate: true,
							projectName: projectName,
						});
					},
				},
				{
					label: "No",
				},
			],
		});
	}

	createTemplate(data) {
		TemplateDataService.create(data)
			.then((response) => {
				this.retrieveProjects();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	onClickDelete(id, name) {
		confirmAlert({
			title: `Confirm to Delete ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteProject(id),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteProject(id) {
		ProjectDataService.delete(id)
			.then((response) => {
				//   console.log(response);
				this.retrieveProjects();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});

				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const {
			searchTitle,
			projects,
			editStatus,
			show,
			showedit,
			templateStatus,
			showtemplate,
			templateProjectId,
			projectName,
		} = this.state; //editProjectId
		const { user: currentUser } = this.props;
		//console.log(this.props);
		if (!currentUser) {
			return <Redirect to="/login" />;
		}

		const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
			<a
				href="_blank"
				ref={ref}
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
			>
				{children}
				<span className="threedots" />
			</a>
		));

		return (
			<div className="list row">
				<div className="col-md-12">
					<div className="input-group mb-3">
						<input
							type="text"
							className="form-control"
							placeholder="Search by title"
							value={searchTitle}
							onChange={this.onChangeSearchTitle}
						/>
						<div className="input-group-append">
							<button
								className="btn btn-outline-secondary"
								type="button"
								onClick={this.searchTitle}
							>
								Search
							</button>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<h4>
						Project List
						{currentUser.project_roles && currentUser.project_roles.add ? (
							<button
								className="btn btn-sm btn-primary float-right"
								type="button"
								onClick={this.addProjectClick}
							>
								Add Project
							</button>
						) : (
							""
						)}
					</h4>
					<hr />

					<Table
						striped
						bordered
						hover
						responsive
						className="product-list-table"
					>
						<thead>
							<tr>
								<th className="hide-desktop"></th>
								<th>NAME</th>
								<th>OWNER</th>
								<th>UPDATED ON</th>
								<th className="hide-mobile">ACTIONS</th>
							</tr>
						</thead>
						{this.state?.filterProjects?.length > 0 ? (
							<tbody>
								{this.state.filterProjects &&
									this.state.filterProjects.map((project, index) => (
										<tr key={index}>
											<td className="hide-desktop">
												<Dropdown className="hide-desktop">
													<Dropdown.Toggle
														id="dropdown-custom-components"
														as={CustomToggle}
													></Dropdown.Toggle>

													<Dropdown.Menu size="sm" title="">
														{currentUser &&
														currentUser.roles === "ROLE_ADMIN" ? (
															<Dropdown.Item
																eventKey="1"
																onClick={() =>
																	this.onClickTemplate(
																		project.id,
																		project.title
																	)
																}
															>
																Template
															</Dropdown.Item>
														) : (
															""
														)}
														{currentUser && currentUser.project_roles.add ? (
															<Dropdown.Item
																eventKey="2"
																onClick={() =>
																	this.onClickClone(project.id, project.title)
																}
															>
																Clone
															</Dropdown.Item>
														) : (
															""
														)}
														{currentUser && currentUser.project_roles.edit ? (
															<Dropdown.Item
																eventKey="3"
																onClick={() => this.onClickEdit(project.id)}
															>
																Edit
															</Dropdown.Item>
														) : (
															""
														)}
														{currentUser && currentUser.project_roles.delete ? (
															<Dropdown.Item
																eventKey="4"
																onClick={() =>
																	this.onClickDelete(project.id, project.title)
																}
															>
																Delete
															</Dropdown.Item>
														) : (
															""
														)}
													</Dropdown.Menu>
												</Dropdown>
											</td>
											<td>
												<Link to={"/folders/" + project.id} className="w-100">
													<FcFolder size={20} />
													{project.title.length > 41
														? project.title.substring(0, 40) + "..."
														: project.title}
												</Link>
											</td>
											<td>
												{!project.createdBy
													? "admin"
													: project.createdBy.username}
											</td>

											<td>{dateFormat(project.updatedAt, "dS mmmm yyyy")}</td>
											<td className="hide-mobile">
												<div className="action-button-grid m-t-0 hide-mobile">
													{currentUser && currentUser.roles === "ROLE_ADMIN" ? (
														<button
															className="btn btn-sm btn-secondary mr-2"
															onClick={() =>
																this.onClickTemplate(project.id, project.title)
															}
														>
															<CgTemplate title="template" size={14} /> Template
														</button>
													) : (
														""
													)}
													{currentUser && currentUser.project_roles.add ? (
														<button
															className="btn btn-sm btn-success mr-2"
															onClick={() =>
																this.onClickClone(project.id, project.title)
															}
														>
															<GoRepoClone title="clone" size={14} /> Clone
														</button>
													) : (
														""
													)}
													{currentUser && currentUser.project_roles.edit ? (
														<button
															className="btn btn-sm btn-warning mr-2 text-white"
															onClick={() => this.onClickEdit(project.id)}
														>
															<RiEditBoxLine title="edit" size={14} /> Edit
														</button>
													) : (
														""
													)}
													{currentUser && currentUser.project_roles.delete ? (
														<button
															className="btn btn-sm btn-danger mr-2"
															onClick={() =>
																this.onClickDelete(project.id, project.title)
															}
														>
															<RiDeleteBin2Line title="delete" size={14} />{" "}
															Delete
														</button>
													) : (
														""
													)}
												</div>
											</td>
										</tr>
									))}
							</tbody>
						) : (
							<tbody>
								{projects &&
									projects.map((project, index) => (
										<tr key={index}>
											<td className="hide-desktop">
												<Dropdown className="hide-desktop">
													<Dropdown.Toggle
														id="dropdown-custom-components"
														as={CustomToggle}
													></Dropdown.Toggle>

													<Dropdown.Menu size="sm" title="">
														{currentUser &&
														currentUser.roles === "ROLE_ADMIN" ? (
															<Dropdown.Item
																eventKey="1"
																onClick={() =>
																	this.onClickTemplate(
																		project.id,
																		project.title
																	)
																}
															>
																Template
															</Dropdown.Item>
														) : (
															""
														)}
														{currentUser && currentUser.project_roles.add ? (
															<Dropdown.Item
																eventKey="2"
																onClick={() =>
																	this.onClickClone(project.id, project.title)
																}
															>
																Clone
															</Dropdown.Item>
														) : (
															""
														)}
														{currentUser && currentUser.project_roles.edit ? (
															<Dropdown.Item
																eventKey="3"
																onClick={() => this.onClickEdit(project.id)}
															>
																Edit
															</Dropdown.Item>
														) : (
															""
														)}
														{currentUser && currentUser.project_roles.delete ? (
															<Dropdown.Item
																eventKey="4"
																onClick={() =>
																	this.onClickDelete(project.id, project.title)
																}
															>
																Delete
															</Dropdown.Item>
														) : (
															""
														)}
													</Dropdown.Menu>
												</Dropdown>
											</td>
											<td>
												<Link to={"/folders/" + project.id} className="w-100">
													<FcFolder size={20} />
													{project.title.length > 41
														? project.title.substring(0, 40) + "..."
														: project.title}
												</Link>
											</td>
											<td>
												{!project.createdBy
													? "admin"
													: project.createdBy.username}
											</td>

											<td>{dateFormat(project.updatedAt, "dS mmmm yyyy")}</td>
											<td className="hide-mobile">
												<div className="action-button-grid m-t-0 hide-mobile">
													{currentUser && currentUser.roles === "ROLE_ADMIN" ? (
														<button
															className="btn btn-sm btn-secondary mr-2"
															onClick={() =>
																this.onClickTemplate(project.id, project.title)
															}
														>
															<CgTemplate title="template" size={14} /> Template
														</button>
													) : (
														""
													)}
													{currentUser && currentUser.project_roles.add ? (
														<button
															className="btn btn-sm btn-success mr-2"
															onClick={() =>
																this.onClickClone(project.id, project.title)
															}
														>
															<GoRepoClone title="clone" size={14} /> Clone
														</button>
													) : (
														""
													)}
													{currentUser && currentUser.project_roles.edit ? (
														<button
															className="btn btn-sm btn-warning mr-2 text-white"
															onClick={() => this.onClickEdit(project.id)}
														>
															<RiEditBoxLine title="edit" size={14} /> Edit
														</button>
													) : (
														""
													)}
													{currentUser && currentUser.project_roles.delete ? (
														<button
															className="btn btn-sm btn-danger mr-2"
															onClick={() =>
																this.onClickDelete(project.id, project.title)
															}
														>
															<RiDeleteBin2Line title="delete" size={14} />{" "}
															Delete
														</button>
													) : (
														""
													)}
												</div>
											</td>
										</tr>
									))}
							</tbody>
						)}
					</Table>
				</div>
				<div className="col-md-4 m-b-3">
					{editStatus ? (
						<EditProject
							showedit={showedit}
							handleEditClose={this.handleEditClose}
							key={this.state.editProjectId}
							retrieveProjects={this.retrieveProjects}
							editProjectId={this.state.editProjectId}
						/>
					) : (
						<AddProject
							projects={projects}
							onClickEdit={this.onClickEdit}
							notify={this.notify}
							show={show}
							handleClose={this.handleClose}
							retrieveProjects={this.retrieveProjects}
						/>
					)}
					{templateStatus ? (
						<AddTemplate
							templateProjectId={templateProjectId}
							projectName={projectName}
							showtemplate={showtemplate}
							handleTemplateClose={this.handleTemplateClose}
							retrieveProjects={this.retrieveProjects}
						/>
					) : (
						""
					)}
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(ProjectList);
