import React, { Component } from "react";
import LocationService from "../../services/location.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { Redirect } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class EditLocation extends Component {
  constructor(props) {
    super(props);
    this.onChangeLocationname = this.onChangeLocationname.bind(this);
    this.handleUpdateLocation = this.handleUpdateLocation.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
   
    this.state = {
      id: null,
      locationname: "",
      submitted: false,
      showmessage: true,
      message:"",
    };
  }

  componentDidMount() {
    this.getLocation();
  }

  getLocation(){
    LocationService.get(this.props.editLocationId)
     .then(response => {
       this.setState({
        locationname: response.data.locationname,
       });

     })
     .catch(e => {
       console.log(e);
     });
   }
 

   onChangeLocationname(e) {
    this.setState({
      locationname: e.target.value
    });
  }

 
  handleEditClose() {
    this.props.handleEditClose();
  }

  handleUpdateLocation(e) {
    e.preventDefault();

    this.setState({
        successful: false,
        showmessage:true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.updateLocation();
    }
  }

  updateLocation() {
  //  alert()
    var data = {
      locationname: this.state.locationname
    };
    
    // console.log(this.state.redirect);
    LocationService.update(this.props.editLocationId,data)
    .then(response => {
      this.handleEditClose();
      this.notify("success",response.data.message);
      this.props.retrieveLocations();
    })
    .catch(error => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
    
      this.setState({
        message: message
      });
      this.notify("error",message);
    });
    
  }

  notify(type,message){ 
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        type:type
      }
    );
  }
  
  render() {
    const { message } = this.state;
    const { user: currentUser } = this.props;
   // console.log(currentUser);
    if (!currentUser) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="submit-form">
         <Modal
          show={this.props.showedit}
          onHide={this.handleEditClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.submitted ? (
              <div>
              </div>
            ) : (
              <div>
                <Form
                    onSubmit={this.handleUpdateLocation}
                    ref={(c) => {
                        this.form = c;
                    }}
                >
                <div className="form-group m-b-10">
                  <label htmlFor="name">Location Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    id="locationname"
                    value={this.state.locationname}
                    onChange={this.onChangeLocationname}
                    name="locationname"
                    validations={[required]}
                  />
                </div>

                {message && (
                  <div className="form-group">
                    {this.state.showmessage && (
                  <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                  </div>
                    )}
                  </div>
                
                )}
                <hr />
                <button className="btn btn-sm btn-primary col-sm-3 m-t-10 btn-block">Update Location</button>
                  <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                      this.checkBtn = c;
                      }}
                  />
                </Form>
              </div>
            )}
           </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(EditLocation);
