import React, { Component } from "react";
//import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import Multiselect from "react-select";

import { connect } from "react-redux";

class Dynamicms extends Component {
	constructor(props) {
		super(props);
		this.handleOnFieldOptions = this.handleOnFieldOptions.bind(this);
		const { selectedValue } = this.props;
		console.log(this.props);

		var newdata = [];
		const array = this.props.msOptionsArray;

		for (let i = 0; i < array.length; i++) {
			var newobject = {};
			if (array[i] !== undefined) {
				newobject.value = array[i];
				newobject.label = array[i];
			}
			newdata.push(newobject);
		}
		console.log(newdata);

		if (selectedValue) {
			console.log(selectedValue);
			var newdataSelected = [];
			var selectedValueArray = selectedValue.split(", ");
			for (let i = 0; i < selectedValueArray.length; i++) {
				var newobjectSelected = {};
				if (selectedValueArray[i] !== undefined) {
					newobjectSelected.value = selectedValueArray[i];
					newobjectSelected.label = selectedValueArray[i];
				}
				newdataSelected.push(newobjectSelected);
			}
			console.log(newdataSelected);
		}

		this.state = {
			fieldoption: newdataSelected,
			msOptionsArray: newdata,
		};

		console.log(this.state);
		// const array = this.props.msOptionsArray;

		// let newObject = {}

		// for (let i=0; i < array.length; i++) {
		//   if (array[i] !== undefined) {
		//     newObject["value"]= array[i]
		//   }
		// }
		// console.log(newObject);

		// var newobject = convertArrayToObject(
		//   this.props.msOptionsArray,
		//   'label',
		// );
		// console.log(newobject);

		// var newobject = Object.assign(
		//   {},
		//   ...this.props.msOptionsArray.map((value) => ({
		//     ['label']: value,
		//   })),
		// )
		// console.log(newobject);

		// handleOnFieldOptions(selected) {
		//   this.setState({
		//     fieldoption: selected
		//   });
	}

	handleOnFieldOptions = (fieldoption) => {
		// console.log(fieldoption);
		this.setState({ fieldoption });
		//  console.log(this.state.fieldoption);
		this.props.setCustomFields(this.props.id, fieldoption, this.props.label);
	};

	render() {
		const { fieldoption } = this.state;
		return (
			<div className="col-sm-6">
				<label>{this.props.label}</label>
				{/* <DropdownMultiselect
          className="form-control col-sm-4"
          options={this.props.msOptionsArray}
          name={this.props.id}
          selected={fieldoption}
          handleOnChange={this.handleOnFieldOptions} /> */}

				<Multiselect
					value={fieldoption}
					onChange={this.handleOnFieldOptions}
					isMulti
					options={this.state.msOptionsArray}
					// getOptionLabel={({ name }) => name}
					className="basic-multi-select"
					classNamePrefix="select"
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Dynamicms);
