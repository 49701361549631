import React, { Component } from "react";
import FolderDataService from "../../services/folder.service";
import Modal from "react-bootstrap/Modal";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

class UploadFile extends Component {
	constructor(props) {
		super(props);
		//this.onChangeTitle = this.onChangeTitle.bind(this);
		this.saveFile = this.saveFile.bind(this);
		this.newFile = this.newFile.bind(this);
		this.selectFile = this.selectFile.bind(this);
		this.handleFileClose = this.handleFileClose.bind(this);
		this.handleAddFile = this.handleAddFile.bind(this);
		this.getAllFileType = this.getAllFileType.bind(this);

		this.state = {
			id: null,
			title: "",
			description: "",
			projectid: this.props.projectid,
			parentid: this.props.parentid,
			status: true,
			submitted: false,
			selectedFiles: undefined,
			currentFile: undefined,
			showmessage: true,
			message: "",
			fileError: false,
			dbfiles: [],
			//    redirect: ""
		};
	}

	componentDidMount() {
		this.getAllFileType();
	}

	getAllFileType() {
		//get files data
		FolderDataService.getAllFileType()
			.then((response) => {
				var res_data = response.data;
				//console.log("res_data", res_data);
				this.setState({
					dbfiles: res_data,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}

	// onChangeTitle(e) {
	// 	this.setState({
	// 		title: e.target.value,
	// 	});
	// }

	selectFile(event) {
		var f = event.target.files;
		for (let i = 0; i < f.length; i++) {
			if (f[i].size > 90000000) {
				// 1024*1024*25 = 90mb
				//alert("File size Greater then 90MiB!");
				this.setState({
					fileError: true,
				});
			} else {
				this.setState({
					selectedFiles: event.target.files,
					fileError: false,
				});
			}
		}

		// this.setState({
		//   selectedFiles: event.target.files,
		//   // fileError: true
		// });
	}

	handleFileClose() {
		this.props.handleFileClose();
	}

	handleAddFile(e) {
		e.preventDefault();

		this.setState({
			successful: false,
			showmessage: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			this.saveFile();
		}
	}

	saveFile() {
		for (let i = 0; i < this.state.selectedFiles.length; i++) {
			var checkFile = this.state.dbfiles.some(
				(item) => item.title === this.state.selectedFiles[i].name
			);

			// let countFilename = this.state.dbfiles.filter(
			// 	(item) => item.title === this.state.selectedFiles[i].name
			// ).length;

			var file_name_array = this.state.selectedFiles[i].name
				.split(".")
				.slice(0, -1)
				.join(".");

			var reg = new RegExp("^" + file_name_array + "_([0-9]+)$");

			var fl_name = this.state.dbfiles.filter((item) => {
				let title = item.title.split(".").slice(0, -1).join(".");
				//console.log("title: ", title, reg.test(title), reg);
				return title === file_name_array || reg.test(title);
			});
			//console.log("fl_name", fl_name);

			let extFilename = this.state.selectedFiles[i].name.split(".").pop();

			if (checkFile) {
				let appendName = fl_name[fl_name.length - 1].title
					.split(".")
					.slice(0, -1)
					.join(".")
					.split("_");
				//console.log("appendName: ", appendName);
				let newAppendName =
					appendName.length > 1 ? Number(appendName.pop()) + 1 : 1;
				var flname = `${file_name_array}_${newAppendName}.${extFilename}`;
			} else {
				flname = this.state.selectedFiles[i].name;
			}

			var data = {
				title: flname, //this.state.title,
				projectid: this.state.projectid,
				type: "file",
				fileurl: this.state.selectedFiles[i], //[0],
				parentid: this.state.parentid,
				status: this.state.status,
				createdBy: this.props.user.id,
				updatedBy: this.props.user.id,
			};

			//console.log("data", data);
			FolderDataService.create(data)
				.then((response) => {
					this.props.retrieveFolders();
					this.notify("success", response.data.message);
					this.props.onSelect(this.props.currentFolder);
					this.handleFileClose();
				})
				.catch((error) => {
					const message =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						message: message,
					});

					this.notify("error", message);
				});
		}
	}

	newFile() {
		this.setState({
			id: null,
			//title: "",
			description: "",
			status: false,
			submitted: false,
		});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { selectedFiles, message } = this.state;
		return (
			<div className="submit-form">
				<Modal
					show={this.props.showfile}
					onHide={this.handleFileClose}
					backdrop="static"
					keyboard={false}
				>
					<Modal.Header closeButton>
						<Modal.Title>Upload File</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.submitted ? (
							<div>
								<h4>You submitted successfully!</h4>
								<button className="btn btn-success" onClick={this.newFile}>
									Add
								</button>
							</div>
						) : (
							<div>
								<div className="breadcrump-manual d-flex no-hover">
									<h4>Path: </h4>
									{this.props.breadcrump.length
										? this.props.breadcrump.map((item, index, arr) => (
												<h4 className="breadcrump-header" key={index}>
													<span className="breadcrump-title">{item.title}</span>{" "}
													<span>{index !== arr.length - 1 ? " > " : ""}</span>
												</h4>
										  ))
										: ""}
								</div>
								<Form
									onSubmit={this.handleAddFile}
									ref={(c) => {
										this.form = c;
									}}
								>
									{/* <div className="form-group m-b-10">
										<label htmlFor="title">File Name</label>
										<Input
											type="text"
											className="form-control m-t-10"
											id="title"
											required
											value={this.state.title}
											onChange={this.onChangeTitle}
											name="title"
											validations={[required]}
										/>
									</div> */}

									<div className="form-group  m-b-10">
										<label htmlFor="fileurl">Upload File</label>
										<Input
											type="file"
											className="form-control  m-t-10"
											id="fileurl"
											required
											onChange={this.selectFile}
											name="fileurl"
											validations={[required]}
											//size= {5242880}
											multiple
										/>
										{this.state.fileError && (
											<span className="sizeError">
												Upload File Size is greater than 5mb
											</span>
										)}
									</div>
									<hr />
									{message && (
										<div className="form-group">
											{this.state.showmessage && (
												<div
													className={
														this.state.successful
															? "alert alert-success"
															: "alert alert-danger"
													}
													role="alert"
												>
													{message}
												</div>
											)}
										</div>
									)}
									<button
										className="btn btn-primary col-sm-3 btn-block"
										disabled={!selectedFiles}
									>
										Add File
									</button>

									<CheckButton
										style={{ display: "none" }}
										ref={(c) => {
											this.checkBtn = c;
										}}
									/>
								</Form>
							</div>
						)}
					</Modal.Body>
				</Modal>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(UploadFile);
