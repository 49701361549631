import React, { Component } from "react";
import FolderDataService from "../../services/folder.service";
import Modal from 'react-bootstrap/Modal';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
//import  { Redirect } from 'react-router-dom'

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class EditFolder extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);    
    this.onChangeRestricted = this.onChangeRestricted.bind(this);
    this.updateFolder = this.updateFolder.bind(this);
    this.newFolder = this.newFolder.bind(this);
    this.handleEditFolder = this.handleEditFolder.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
   // this.redirectToPage = this.redirectToPage.bind(this);
   // console.log(this.props.currentFolder);
    this.state = {
      id: null,
      title: "",
      projectid:this.props.projectid,
      parentid: this.props.parentid,
      status: true,
      submitted: false,
      showmessage:true,
      message:"",
      restricted: false
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value
    });
  }

  onChangeRestricted(e) {
    this.setState({ restricted: e.target.checked });
  }

  handleEditClose() {
      this.props.handleEditClose();
  }

  componentDidMount() {
    this.getFolder();
  }

  getFolder(){
    FolderDataService.get(this.props.editFolderId)
     .then(response => {
       this.setState({
         title: response.data.title,
         restricted: response.data.restricted         
       });

     })
     .catch(e => {
       console.log(e);
     });
   }

  handleEditFolder(e) {
    e.preventDefault();

    this.setState({
        successful: false,
        showmessage:true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.updateFolder();
    }
  }

  updateFolder() {
  //  alert()
    var data = {
      title: this.state.title,
      restricted:this.state.restricted,
      status: this.state.status,
    };
    
   // console.log(this.state.redirect);
    FolderDataService.update(this.props.editFolderId,data)
      .then(response => {
        this.newFolder();
        this.props.retrieveFolders();
        this.notify("success","File has been uploaded successfully.");
        this.props.onSelect(this.props.currentFolder);
        this.handleEditClose();
      })
      .catch(error => {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
      
        this.setState({
          message: message
        });
        this.notify("error",message);
      });
  }

  newFolder() {
    this.setState({
      id: null,
      title: "",
      status: false,
      submitted: false
    });
  }

  notify(type,message){ 
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        type:type
      }
    );
  }

  render() {
    const { message } = this.state;
      return (
      <div className="submit-form">

        <Modal
          show={this.props.showedit}
          onHide={this.handleEditClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={this.newFolder}>
                Add
              </button>
            </div>
            ) : (
            <div>
               <Form
                  onSubmit={this.handleEditFolder}
                  ref={(c) => {
                      this.form = c;
                  }}
                >
                <div className="form-group m-b-10">
                  <label htmlFor="title">Title</label>
                  <Input
                    type="text"
                    className="form-control"
                    id="title"
                    value={this.state.title}
                    onChange={this.onChangeTitle}
                    name="title"
                    validations={[required]}
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="checkbox"
                    id="restricted"
                    checked={this.state.restricted}
                    onChange={this.onChangeRestricted}
                    name="restricted"
                  /> Restricted
                </div>

                <hr />
                  {message && (
                    <div className="form-group">
                      {this.state.showmessage && (
                    <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                        {message}
                    </div>
                      )}
                    </div>
                  
                  )}
                  <button className="btn btn-primary col-sm-4 btn-block">Update Folder</button>
                  
                  <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                      this.checkBtn = c;
                      }}
                  />
                </Form>

              
            </div>
          )}
          </Modal.Body>
        </Modal>
        <ToastContainer />             
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(EditFolder);

