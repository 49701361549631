import http from "../http-common";
import authHeader from "./auth-header";

class LogDataService {
	getAll() {
		return http.get("/logs", { headers: authHeader() });
	}

	get(id) {
		return http.get(`/logs/${id}`);
	}

	create(data) {
		return http.post("/logs", data);
	}

	update(id, data) {
		return http.put(`/logs/${id}`, data);
	}

	delete(id) {
		return http.delete(`/logs/${id}`);
	}

	deleteAll() {
		return http.delete(`/logs`);
	}

	findByTitle(title) {
		return http.get(`/logs?title=${title}`);
	}
}

export default new LogDataService();
