import React, { Component } from "react";
import FolderDataService from "../../services/folder.service";
import Modal from "react-bootstrap/Modal";
//import  { Redirect } from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

class AddFolder extends Component {
	constructor(props) {
		super(props);
		this.onChangeTitle = this.onChangeTitle.bind(this);
		this.onChangeRestricted = this.onChangeRestricted.bind(this);
		this.saveFolder = this.saveFolder.bind(this);
		this.newFolder = this.newFolder.bind(this);
		this.handleAddFolder = this.handleAddFolder.bind(this);
		this.handleClose = this.handleClose.bind(this);
		// this.redirectToPage = this.redirectToPage.bind(this);
		// console.log(this.props.currentFolder);
		this.state = {
			id: null,
			title: "",
			projectid: this.props.projectid,
			parentid: this.props.parentid,
			status: true,
			submitted: false,
			showmessage: true,
			message: "",
			restricted: false,
		};
	}

	onChangeTitle(e) {
		this.setState({
			title: e.target.value,
		});
	}

	onChangeRestricted(e) {
		this.setState({ restricted: e.target.checked });
	}

	handleClose() {
		this.props.handleClose();
	}

	handleAddFolder(e) {
		e.preventDefault();

		this.setState({
			successful: false,
			showmessage: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			this.saveFolder();
		}
	}

	saveFolder() {
		//  alert()
		var data = {
			title: this.state.title,
			projectid: this.state.projectid,
			parentid: this.state.parentid,
			type: "folder",
			url: "",
			restricted: this.state.restricted,
			status: this.state.status,
			createdBy: this.props.user.id,
			updatedBy: this.props.user.id,
		};

		// console.log(this.state.redirect);
		FolderDataService.create(data)
			.then((response) => {
				this.newFolder();
				this.props.retrieveFolders();
				this.notify("success", "Folder has been created successfully.");
				this.props.onSelect(this.props.currentFolder);
				this.handleClose();
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	newFolder() {
		this.setState({
			id: null,
			title: "",
			restricted: false,
			status: false,
			submitted: false,
		});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { message } = this.state;
		//console.log("breadcrump: ", this.props.breadcrump);
		return (
			<div className="submit-form">
				{/* <Loader
         visible="true"
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      /> */}
				<Modal
					show={this.props.show}
					onHide={this.handleClose}
					backdrop="static"
					keyboard={false}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add Folder</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.submitted ? (
							<div>
								<h4>You submitted successfully!</h4>
								<button className="btn btn-success" onClick={this.newFolder}>
									Add
								</button>
							</div>
						) : (
							<div>
								<div className="breadcrump-manual d-flex no-hover">
									<h4>Path: </h4>
									{this.props.breadcrump.length
										? this.props.breadcrump.map((item, index, arr) => (
												<h4 className="breadcrump-header" key={index}>
													<span className="breadcrump-title">{item.title}</span>{" "}
													<span>{index !== arr.length - 1 ? " > " : ""}</span>
												</h4>
										  ))
										: ""}
								</div>
								<Form
									onSubmit={this.handleAddFolder}
									ref={(c) => {
										this.form = c;
									}}
								>
									<div className="form-group m-b-10">
										<label htmlFor="title">Folder Name</label>
										<Input
											type="text"
											className="form-control"
											id="title"
											value={this.state.title}
											onChange={this.onChangeTitle}
											name="title"
											validations={[required]}
										/>
									</div>
									<div className="col-sm-3">
										<input
											type="checkbox"
											id="restricted"
											checked={this.state.restricted}
											onChange={this.onChangeRestricted}
											name="restricted"
										/>{" "}
										Restricted
									</div>
									<hr />
									{message && (
										<div className="form-group">
											{this.state.showmessage && (
												<div
													className={
														this.state.successful
															? "alert alert-success"
															: "alert alert-danger"
													}
													role="alert"
												>
													{message}
												</div>
											)}
										</div>
									)}
									<button className="btn btn-primary col-sm-3 btn-block">
										Add Folder
									</button>

									<CheckButton
										style={{ display: "none" }}
										ref={(c) => {
											this.checkBtn = c;
										}}
									/>
								</Form>
							</div>
						)}
					</Modal.Body>
				</Modal>

				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(AddFolder);
