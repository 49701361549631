import http from "../http-common";
import authHeader from './auth-header';


class UserService {
  getPublicContent() {
    return http.get('/test/all');
  }

  getUserBoard() {
    return http.get('/test/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return http.get('/test/mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return http.get('/test/admin', { headers: authHeader() });
  }

   getAll(){
    return http.get('/test/allusers', { headers: authHeader() });
   }
   
  delete(id) {
    return http.delete(`/test/delete/${id}`, { headers: authHeader() });
  }
}

export default new UserService();