import React, { Component } from "react";
//import Input from "react-validation/build/input";

import { connect } from "react-redux";

class Dynamictxt extends Component {
	constructor(props) {
		super(props);

		this.handleOnFieldText = this.handleOnFieldText.bind(this);
		const { selectedValue } = this.props;
		this.state = {
			fieldtxt: selectedValue || "",
			label: this.props.label,
		};
	}

	handleOnFieldText(e) {
		this.setState({
			fieldtxt: e.target.value,
		});

		this.props.setCustomFields(this.props.id, e.target.value, this.props.label);
	}

	render() {
		const { fieldtxt } = this.state;
		return (
			<div className="col-sm-6">
				<label>{this.state.label}</label>
				<input
					type="text"
					className="form-control"
					name={this.props.id}
					value={fieldtxt}
					onChange={(e) => this.handleOnFieldText(e)}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Dynamictxt);
