import React, { Component } from "react";

import UserService from "../../services/user.service";
import Register from "../auth/register.component";
import Table from "react-bootstrap/Table";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RiDeleteBin2Line } from "react-icons/ri";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";

class Users extends Component {
	constructor(props) {
		super(props);

		this.retrieveUsers = this.retrieveUsers.bind(this);

		this.state = {
			users: [],
		};
	}

	//   componentDidMount() {
	//     UserService.getAdminBoard().then(
	//       response => {
	//         this.setState({
	//           content: response.data
	//         });
	//       },
	//       error => {
	//         this.setState({
	//           content:
	//             (error.response &&
	//               error.response.data &&
	//               error.response.data.message) ||
	//             error.message ||
	//             error.toString()
	//         });
	//       }
	//     );
	//   }

	componentDidMount() {
		this.retrieveUsers();
	}

	retrieveUsers() {
		// get Users Data
		UserService.getAll()
			.then((response) => {
				var res_data = response.data;
				var b = res_data.filter((e) => e.username === "admin");
				b.forEach((f) =>
					res_data.splice(
						res_data.findIndex((e) => e.username === f.username),
						1
					)
				);

				this.setState({
					users: res_data,
				});
			})
			// .catch((e) => {
			// 	console.log(e);
			// });
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
				}
			});
	}

	onClickDelete(locationid, name) {
		confirmAlert({
			title: `Confirm to Delete ${name} user`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteUser(locationid),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteUser(userid) {
		UserService.delete(userid)
			.then((response) => {
				//console.log("response" + JSON.stringify(response) )
				this.retrieveUsers();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { users } = this.state;
		const { user: currentUser } = this.props;

		if (!currentUser || currentUser.roles !== "ROLE_ADMIN") {
			return <Redirect to="/login" />;
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-sm-6">
						<h4>User List</h4>
						<hr />
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th>User Name</th>
									<th>Full Name</th>
									<th>Role</th>
									<th>action</th>
								</tr>
							</thead>
							<tbody>
								{users &&
									users.map((user, index) => (
										<tr key={index}>
											<td>{user.username}</td>
											<td>{user.fullname}</td>
											<td>{user.role.name}</td>
											<td>
												<div className="action-button-grid m-t-0">
													<RiDeleteBin2Line
														size={22}
														onClick={() =>
															this.onClickDelete(user.id, user.username)
														}
													/>
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</div>
					<div className="col-sm-6">
						<Register retrieveUsers={this.retrieveUsers} />
					</div>
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Users);
