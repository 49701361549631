import React, { Component } from "react";
import TemplateService from "../../services/template.service";
import Table from "react-bootstrap/Table";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

import { RiInformationLine, RiDeleteBin2Line } from "react-icons/ri";
import { IoIosArrowDropright } from "react-icons/io";
import { Redirect } from "react-router-dom";

import Treeview from "../treeview/treeview.component";

import { connect } from "react-redux";
import { logout } from "../../actions/auth";

class Template extends Component {
	constructor(props) {
		super(props);

		this.retrieveTemplates = this.retrieveTemplates.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			templates: [],
			showadd: false,
			showedit: false,
			editStatus: false,
			projectid: "",
		};
	}

	componentDidMount() {
		this.retrieveTemplates();
	}

	retrieveTemplates() {
		// get Locations Data
		TemplateService.getAll()
			.then((response) => {
				this.setState({
					templates: response.data,
				});
			})
			.catch((error) => {
				console.log(error.response);
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				if (error.response.status === 401) {
					localStorage.removeItem("user");
					this.props.dispatch(logout());
				}
			});
	}

	handleClose() {
		this.setState({
			showadd: false,
		});
	}

	handleEditClose() {
		this.setState({
			showedit: false,
		});
	}

	onClickShowTree(projectId) {
		this.setState({
			projectid: projectId,
		});
	}

	onClickDelete(templateid, name) {
		confirmAlert({
			title: `Confirm to Delete ${name}`,
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => this.deleteTemplate(templateid),
				},
				{
					label: "No",
				},
			],
		});
	}

	deleteTemplate(templateid) {
		TemplateService.delete(templateid)
			.then((response) => {
				this.retrieveTemplates();
				this.notify("success", response.data.message);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		const { templates } = this.state;
		const { user: currentUser } = this.props;
		//console.log(currentUser);
		if (!currentUser || currentUser.roles !== "ROLE_ADMIN") {
			return <Redirect to="/login" />;
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-sm-8">
						<h4>Template List</h4>
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th>Template Name</th>
									<th>Project Name</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{templates &&
									templates.map((template, index) => (
										<tr key={index}>
											<td>
												{template.templatename ? template.templatename : ""}
											</td>
											<td>
												{template.projectId?.title
													? template.projectId?.title
													: ""}
											</td>
											<td>
												<div className="action-button-grid m-t-0">
													<Link
														to={"/folders/" + template.projectId?._id}
														className=" "
													>
														<RiInformationLine size={22} />
													</Link>
													<IoIosArrowDropright
														size={22}
														onClick={() =>
															this.onClickShowTree(template.projectId?._id)
														}
													/>
													<RiDeleteBin2Line
														size={22}
														onClick={() =>
															this.onClickDelete(
																template.id,
																template.templatename
															)
														}
													/>
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</div>
					<div className="col-sm-4">
						<Treeview
							key={this.state.projectid}
							projectid={this.state.projectid}
						/>
					</div>
				</div>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Template);
