import React, { Component } from "react";
import FolderDataService from "../../services/folder.service";
import ProjectDataService from "../../services/project.service";

import Tree from '@naisutech/react-tree';

import { connect } from "react-redux";


class Treeview extends Component {
    constructor(props) {
      super(props);

      this.state = {
        folders: [],
        title: "",
        projectName: ""
      };
    }

    componentDidMount() {
      this.getFolderList();
    }

    getFolderList(){
      console.log(this.props.projectid);
      ProjectDataService.get(this.props.projectid)
      .then(response => {
        console.log(response.data);
        this.setState({
          projectName: response.data.title,
        });
  
        //get folders data
        FolderDataService.getByProject(this.props.projectid)
        .then(response => {
          var res_data = response.data;
  
          // this is creating top root folder
          var root_folder = {
            id: this.props.projectid,
            parentid: null,
            label: this.state.projectName,
            title: this.state.projectName,
            description: this.state.projectDescription
          }
          res_data.unshift(root_folder);
  
          // This is for creating lable parentID and itesm required to show tree
          var i;
          for (i = 0; i < res_data.length; i++) {
            res_data[i].label = res_data[i]['title'];
            res_data[i].parentId = res_data[i]['parentid'];
            res_data[i].items = []
          }
  
          // this code is for add files under the parent folders
          res_data.filter(element => {
            if (element.type === "file") {
              res_data.filter(function (item) {
                if (item['id'] === element.parentId) {
                  item.items.push(element); // pushing files inside folders
                }
              });
            }
          });
          
          // code is to remove files from the list
          var b = res_data.filter(e => e.type === "file");
          b.forEach(f => res_data.splice(res_data.findIndex(e => e.type === f.type), 1));
  
          // //restrict to show same folder while moving to another
          // var c = res_data.filter(e => e.id === this.props.editMoveFileFolderId);
          // c.forEach(f => res_data.splice(res_data.findIndex(e => e.id === f.id), 1));
  
          this.setState({
            folders: res_data
          });
        })
        .catch(e => {
          console.log(e);
        });
  
      });
    }
  
    render() {
      const { folders } = this.state;
        return (
          <div>
            <h6>Folders List</h6> <hr />
            <Tree
              nodes={folders}
              onSelect={this.onMoveSelect}
              theme={'light'}
              size={'full'} grow showEmptyItems />
          </div>
      );
    }
  }
  
  function mapStateToProps(state) {
    const { user } = state.auth;
    return {
      user,
    };
  }
  
  export default connect(mapStateToProps)(Treeview);
  
  