import React, { Component } from "react";

import ProjectDataService from "../../services/project.service";
import LocationDataService from "../../services/location.service";
import CustomFieldDataService from "../../services/customfield.service";

import Dynamictxt from "./dynamic-txt.component";
import Dynamicmc from "./dynamic-mc.component";
import Dynamicms from "./dynamic-ms.component";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import Multiselect from "react-select";

//import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

class AddNewProject extends Component {
	constructor(props) {
		super(props);
		this.onChangeTitle = this.onChangeTitle.bind(this);
		this.onChangeDescription = this.onChangeDescription.bind(this);
		this.onChangeLocation = this.onChangeLocation.bind(this);
		this.onChangeOfficeFileNumber = this.onChangeOfficeFileNumber.bind(this);
		this.onChangeVPNumber = this.onChangeVPNumber.bind(this);
		this.onChangeBPNumber = this.onChangeBPNumber.bind(this);
		this.saveProject = this.saveProject.bind(this);
		this.newProject = this.newProject.bind(this);
		this.retrieveLocations = this.retrieveLocations.bind(this);
		this.retrieveCustomFields = this.retrieveCustomFields.bind(this);
		this.handleAddProject = this.handleAddProject.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleOnFieldChange = this.handleOnFieldChange.bind(this);
		this.createTextUI = this.createTextUI.bind(this);
		this.setCustomFields = this.setCustomFields.bind(this);

		this.state = {
			id: null,
			title: "",
			description: "",
			location: "",
			locationOptions: [],
			officefilenumber: "",
			vpnumber: "",
			bpnumber: "",
			status: true,
			submitted: false,
			showmessage: true,
			message: "",
			fieldOptionsArray: [],
			values: [],
			fieldoption: "",
			fieldtxt: "",
			customOptions: [],
		};
	}

	onChangeTitle(e) {
		this.setState({
			title: e.target.value,
		});
	}

	onChangeDescription(e) {
		this.setState({
			description: e.target.value,
		});
	}

	onChangeLocation(e) {
		this.setState({
			location: e.target.value,
		});
	}

	onChangeOfficeFileNumber(e) {
		this.setState({
			officefilenumber: e.target.value,
		});
	}

	onChangeVPNumber(e) {
		this.setState({
			vpnumber: e.target.value,
		});
	}

	onChangeBPNumber(e) {
		this.setState({
			bpnumber: e.target.value,
		});
	}


	// handleOnFieldChange1 = fieldoption => {
	//   this.setState({ fieldoption });
	//   console.log(`Option selected:`, fieldoption);
	// };

	handleOnFieldChange = (fieldoption) => {
		console.log(fieldoption);
		this.setState({ fieldoption }); /// this is for show selected option
		// this.setState({values : []});

		//if removed then removed from values
		var values = this.state.values;
		values = values.filter(function (o1) {
			return fieldoption.some(function (o2) {
				return o1.id === o2.id; // return the ones with equal id
			});
		});
		this.setState({ values: values });

		fieldoption.map((option, index) => {
			index = index + 1;
			// console.log(option);
			CustomFieldDataService.get(option.id)
				.then((response) => {
					var id = response.data.id;
					var label = response.data.customfieldname;
					var fieldtype = response.data.fieldtype;
					var mcmsoptions = response.data.mcmsoptions;

					if (!this.exists(this.state.values, option.id)) {
						if (fieldtype === "txt") {
							this.setState({
								values: [
									...this.state.values,
									{
										element: this.createTextUI(label, index, id),
										id: id,
										value: "",
									},
								],
							});
						} else if (fieldtype === "mc") {
							this.setState({
								values: [
									...this.state.values,
									{
										element: this.createMcUI(label, mcmsoptions, index, id),
										id: id,
										value: "",
									},
								],
							});
						} else if (fieldtype === "ms") {
							this.setState({
								values: [
									...this.state.values,
									{
										element: this.createMsUI(label, mcmsoptions, index, id),
										id: id,
										value: "",
									},
								],
							});
						}
					} else {
						//this.showcustomFieldsOnEdit([option.id], editOption, fieldoption);
					}
					console.log("values", this.state.values);
				})
				.catch((e) => {
					console.log(e);
				});
		});
	};

	// handleOnFieldOptions(selected) {
	//   console.log(selected);
	// }

	setCustomFields(id, fieldvalue, label) {
		let values = this.state.values;

		if (Array.isArray(fieldvalue)) {
			var fieldvalues = "";
			for (var i = 0; i < fieldvalue.length; i++) {
				fieldvalues += fieldvalue[i].value + ", ";
			}
			fieldvalues = fieldvalues.slice(0, -2);
		} else {
			fieldvalues = fieldvalue;
		}

		this.setState({
			values: values.map((item) => {
				if (item.id === id) {
					item.value = fieldvalues;
					item.label = label;
				}
				return item;
			}),
		});
	}

	createTextUI(label, index, id) {
		return (
			<Dynamictxt
				label={label}
				setCustomFields={this.setCustomFields}
				key={index}
				id={id}
			/>
		);
	}

	createMcUI(label, mcmsoptions, index, id) {
		return (
			<Dynamicmc
				label={label}
				msOptionsArray={mcmsoptions}
				setCustomFields={this.setCustomFields}
				key={index}
				id={id}
			/>
		);
	}

	createMsUI(label, mcmsoptions, index, id) {
		return (
			<Dynamicms
				label={label}
				msOptionsArray={mcmsoptions}
				setCustomFields={this.setCustomFields}
				key={index}
				id={id}
			/>
		);
	}

	componentDidMount() {
		this.retrieveLocations();
		this.retrieveCustomFields();
	}

	retrieveLocations() {
		LocationDataService.getAll()
			.then((response) => {
				this.setState({
					locationOptions: response.data,
				});
				console.log(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	exists(arr, cid) {
		console.log(arr);
		console.log(cid);
		return arr.some(function (el) {
			return el.id === cid;
		});
	}

	async retrieveCustomFields() {
		const customFields = await CustomFieldDataService.getAll();
		console.log("customFields", customFields);
		if (customFields.statusText === "OK" && customFields.status === 200) {
			const data = customFields.data;
			for (var i = 0; i < data.length; i++) {
				data[i].value = data[i]["id"];
				data[i].label = data[i]["customfieldname"];
			}
			this.setState({ fieldOptionsArray: data });
		} else {
			console.log("Error Fetching Custom Fields");
		}
	}


	handleClose() {
		this.props.handleClose();
	}

	handleAddProject(e) {
		e.preventDefault();

		if (this.checkBtn.context._errors.length === 0) {
			this.saveProject();
		}
		this.form.validateAll();

		this.setState({
			successful: false,
			showmessage: true,
		});

		// this.form.validateAll();

		// if (this.checkBtn.context._errors.length === 0) {
		// 	this.saveProject();
		// }
	}

	saveProject() {
		//  alert()
		var data = {
			title: this.state.title,
			description: this.state.description,
			location: this.state.location,
			officefilenumber: this.state.officefilenumber,
			vpnumber: this.state.vpnumber,
			bpnumber: this.state.bpnumber,
			status: this.state.status,
			customoptions: this.state.values.map(
				(item) => (delete item.element, item)
			),
			createdBy: this.props.user.id,
			updatedBy: this.props.user.id,
		};

		// console.log(data);
		ProjectDataService.create(data)
			.then((response) => {
				this.handleClose();
				this.newProject();
				this.notify("success", "Project has been created successfully.");
				this.props.retrieveProjects();
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	newProject() {
		this.setState({
			id: null,
			title: "",
			description: "",
			location: "",
			officefilenumber: "",
			vpnumber: "",
			bpnumber: "",
			status: false,
			submitted: false,
		});
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		// const { redirect } = this.state;
		// if (redirect) {
		//   return <Redirect to={this.state.redirect} />
		// }
		const { message, values, fieldoption } = this.state;
		return (
			<div>
				<Form
					onSubmit={this.handleAddProject}
					ref={(c) => {
						this.form = c;
					}}
				>
					<div className="row">
						<div className="form-group col-sm-4 m-b-10">
							<label htmlFor="title">Title</label>
							<Input
								type="text"
								className="form-control"
								id="title"
								value={this.state.title}
								onChange={this.onChangeTitle}
								name="title"
								validations={[required]}
							/>
						</div>

						<div className="form-group col-sm-4 m-b-10">
							<label htmlFor="description">Description</label>
							<Input
								type="text"
								className="form-control"
								id="description"
								value={this.state.description}
								onChange={this.onChangeDescription}
								name="description"
							/>
						</div>

						<div className="form-group col-sm-4 m-b-10">
							<label htmlFor="location">Location</label>
							<Select
								className="form-control m-t-10"
								id="location"
								name="location"
								value={this.state.location}
								onChange={this.onChangeLocation}
								validations={[required]}
							>
								<option value="">Select Location</option>
								{this.state.locationOptions
									.sort(function (a, b) {
										var nameA = a.locationname.toUpperCase(); // ignore upper and lowercase
										var nameB = b.locationname.toUpperCase(); // ignore upper and lowercase
										if (nameA < nameB) {
											return -1; //nameA comes first
										}
										if (nameA > nameB) {
											return 1; // nameB comes first
										}
										return 0; // names must be equal
									})
									.map((option) => (
										<option value={option.id}>{option.locationname}</option>
									))}
							</Select>
						</div>
					</div>
					<div className="row">
						<div className="form-group col-sm-4 m-b-10">
							<label htmlFor="officefilenumber">Office File Number</label>
							<Input
								type="text"
								className="form-control"
								id="officefilenumber"
								value={this.state.officefilenumber}
								onChange={this.onChangeOfficeFileNumber}
								name="officefilenumber"
								validations={[required]}
							/>
						</div>
						<div className="form-group col-sm-4 m-b-10">
							<label htmlFor="vpnumber"> VP Number</label>
							<Input
								type="text"
								className="form-control"
								id="vpnumber"
								value={this.state.vpnumber}
								onChange={this.onChangeVPNumber}
								name="vpnumber"
							/>
						</div>
						<div className="form-group col-sm-4 m-b-10">
							<label htmlFor="bpnumber"> BP Number</label>
							<Input
								type="text"
								className="form-control"
								id="bpnumber"
								value={this.state.bpnumber}
								onChange={this.onChangeBPNumber}
								name="bpnumber"
							/>
						</div>

					</div>
					<hr />
					<h6>Custom Fields</h6>
					<div className="form-group col-sm-6 m-b-10">
						<Multiselect
							value={fieldoption}
							onChange={this.handleOnFieldChange}
							isMulti
							options={this.state.fieldOptionsArray}
							className="basic-multi-select"
							classNamePrefix="select"
						/>
						{/* <DropdownMultiselect 
                    className="form-control col-sm-4" 
                    options={this.state.fieldOptionsArray} 
                    name="fieldoptions" 
                    handleOnChange = {this.handleOnFieldChange} /> */}
					</div>

					{/* {this.createTextUI("new")} */}
					{values.length ? values.map((option) => option.element) : ""}
					{message && (
						<div className="form-group">
							{this.state.showmessage && (
								<div
									className={
										this.state.successful
											? "alert alert-success"
											: "alert alert-danger"
									}
									role="alert"
								>
									{message}
								</div>
							)}
						</div>
					)}
					<hr />
					<button className="btn btn-sm btn-primary col-sm-3 btn-block m-t-10">
						Add Project
					</button>
					{/* <button onClick={this.saveProject} className="btn btn-success m-t-10">
                    Submit
                    </button> */}
					<CheckButton
						style={{ display: "none" }}
						ref={(c) => {
							this.checkBtn = c;
						}}
					/>
				</Form>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(AddNewProject);
