import React, { Component } from "react";
import CustomFieldDataService from "../../services/customfield.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
//import  { Redirect } from 'react-router-dom'

import Modal from "react-bootstrap/Modal";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

class AddCustomField extends Component {
	constructor(props) {
		super(props);
		this.onChangeCustomFieldName = this.onChangeCustomFieldName.bind(this);
		this.onChangeFieldType = this.onChangeFieldType.bind(this);
		this.saveCustomField = this.saveCustomField.bind(this);
		this.newCustomField = this.newCustomField.bind(this);
		this.handleCustomField = this.handleCustomField.bind(this);
		this.onChangeMcMsOptions = this.onChangeMcMsOptions.bind(this);
		this.createUI = this.createUI.bind(this);
		this.handleClose = this.handleClose.bind(this);

		// this.redirectToPage = this.redirectToPage.bind(this);
		//   console.log(this.props.currentFolder);
		this.state = {
			id: null,
			customfieldname: "",
			status: true,
			submitted: false,
			fieldtype: "txt",
			fieldTypeOptions: [
				{ id: "txt", fieldtype: "Text Field" },
				{ id: "mc", fieldtype: "Multiple Choice" },
				{ id: "ms", fieldtype: "Multiple Select" },
			],
			showmessage: true,
			message: "",
			showMcMsOptions: false,
			mcmsoptions: "",
			// inputs: [],
			values: [],
		};
	}

	onChangeCustomFieldName(e) {
		this.setState({
			customfieldname: e.target.value,
		});
	}

	onChangeFieldType(e) {
		if (e.target.value !== "txt") {
			this.setState({
				fieldtype: e.target.value,
				showMcMsOptions: true,
			});
		} else {
			this.setState({
				fieldtype: e.target.value,
				showMcMsOptions: false,
			});
		}
	}

	onChangeMcMsOptions(e) {
		this.setState({
			mcmsoptions: e.target.value,
		});
	}

	handleClose() {
		this.props.handleClose();
	}

	handleCustomField(e) {
		e.preventDefault();

		this.setState({
			successful: false,
			showmessage: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			this.saveCustomField();
		}
	}

	saveCustomField() {
		this.state.mcmsoptions = this.state.values;
		// this.setState({
		// 	mcmsoptions: this.state.values,
		// });
		var data = {
			customfieldname: this.state.customfieldname,
			fieldtype: this.state.fieldtype,
			mcmsoptions: this.state.mcmsoptions,
			status: this.state.status,
		};

		// console.log(this.state.redirect);
		CustomFieldDataService.create(data)
			.then((response) => {
				this.handleClose();
				this.newCustomField();
				this.notify("success", "Custom Field has been created successfully.");
				this.props.retrieveCustomFields();
				this.setState({
					showmessage: false,
				});
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	newCustomField() {
		this.setState({
			id: null,
			customfieldname: "",
			status: false,
			submitted: false,
			fieldtype: "txt",
			showMcMsOptions: false,
			values: [],
		});
	}

	// appendInput() {
	//   var newInput = `input-${this.state.inputs.length}`;
	//   this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
	// }
	createUI() {
		return this.state.values.map((el, i) => (
			<div className="row m-l-0 m-r-0" key={i}>
				<Input
					type="text"
					className="form-control col-sm-9"
					value={el || ""}
					onChange={this.handleChange.bind(this, i)}
					validations={[required]}
				/>
				<Input
					type="button"
					className="m-b-10 p-r-20 btn btn-danger offset-sm-1 col-sm-1"
					value="X"
					onClick={this.removeClick.bind(this, i)}
				/>
			</div>
		));
	}

	handleChange(i, event) {
		let values = [...this.state.values];
		values[i] = event.target.value;
		this.setState({ values });
	}

	addClick(e) {
		e.preventDefault();
		this.setState((prevState) => ({ values: [...prevState.values, ""] }));
	}

	removeClick(i) {
		let values = [...this.state.values];
		values.splice(i, 1);
		this.setState({ values });
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		// const { redirect } = this.state;
		// if (redirect) {
		//   return <Redirect to={this.state.redirect} />
		// }
		const { message, showMcMsOptions } = this.state;
		return (
			<div className="submit-form">
				<Modal
					show={this.props.showadd}
					onHide={this.handleClose}
					backdrop="static"
					keyboard={false}
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title>Add Custom Fields</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.submitted ? (
							<div>
								<h4>You submitted successfully!</h4>
								<button className="btn btn-success" onClick={this.newFolder}>
									Add
								</button>
							</div>
						) : (
							<div>
								<Form
									onSubmit={this.handleCustomField}
									ref={(c) => {
										this.form = c;
									}}
								>
									<div className="form-group m-b-10">
										<label htmlFor="customfieldname">Custom Field Name</label>
										<Input
											type="text"
											className="form-control"
											id="customfieldname"
											value={this.state.customfieldname}
											onChange={this.onChangeCustomFieldName}
											name="customfieldname"
											validations={[required]}
										/>
									</div>

									<div className="form-group m-b-10">
										<label htmlFor="fieldtype">Select Field Type</label>
										<Select
											className="form-control m-t-10"
											id="fieldtype"
											name="fieldtype"
											value={this.state.fieldtype}
											onChange={this.onChangeFieldType}
											validations={[required]}
										>
											{this.state.fieldTypeOptions.map((option, index) => (
												<option value={option.id} key={index}>
													{option.fieldtype}
												</option>
											))}
										</Select>
									</div>
									{/* <div id="dynamicInput">
                      {this.state.inputs.map(input => <Input className="form-control m-t-10" placeholder="options" name="fieldtype[]" key={input} />)}
                  </div> */}

									{showMcMsOptions ? (
										<div>
											<button
												className="m-b-10 btn btn-sm btn-success"
												onClick={this.addClick.bind(this)}
											>
												Add Options
											</button>
											{this.createUI()}
										</div>
									) : (
										// <div className="form-group m-b-10">
										//   <label htmlFor="mcmsoptions">Options (Comma Seperated)</label>
										//   <Input
										//     type="text"
										//     className="form-control"
										//     id="mcmsoptions"
										//     value={this.state.mcmsoptions}
										//     onChange={this.onChangeMcMsOptions}
										//     name="mcmsoptions"
										//   />
										// </div>
										""
									)}
									{message && (
										<div className="form-group">
											{this.state.showmessage && (
												<div
													className={
														this.state.successful
															? "alert alert-success"
															: "alert alert-danger"
													}
													role="alert"
												>
													{message}
												</div>
											)}
										</div>
									)}
									<hr />
									<button className="btn btn-sm btn-primary col-sm-6 btn-block">
										Add Custom Field
									</button>

									<CheckButton
										style={{ display: "none" }}
										ref={(c) => {
											this.checkBtn = c;
										}}
									/>
								</Form>
							</div>
						)}
					</Modal.Body>
				</Modal>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(AddCustomField);
