import http from "../http-common";
import authHeader from "./auth-header";

class FolderDataService {
	getAll() {
		return http.get("/folders");
	}

	getAllFileType() {
		return http.get("/folders/file");
	}

	getByProject(project_id) {
		return http.get(`/folders/getByProject/${project_id}`, {
			headers: authHeader(),
		});
	}

	getByParent(parent_id) {
		return http.get(`/folders/getByParent/${parent_id}`);
	}

	get(id) {
		return http.get(`/folders/${id}`);
	}

	create(data) {
		let formData = new FormData();

		formData.append("title", data.title);
		formData.append("projectid", data.projectid);
		formData.append("parentid", data.parentid);
		formData.append("type", data.type);
		formData.append("fileurl", data.fileurl);
		formData.append("restricted", data.restricted);
		formData.append("status", data.status);
		formData.append("createdBy", data.createdBy);
		formData.append("updatedBy", data.updatedBy);

		return http.post("/folders", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}

	update(id, data) {
		return http.put(`/folders/${id}`, data);
	}

	delete(id) {
		return http.delete(`/folders/${id}`);
	}

	deleteAll() {
		return http.delete(`/folders`);
	}

	findByTitle(title) {
		return http.get(`/folders?title=${title}`);
	}
}

export default new FolderDataService();
