import http from "../http-common";
import authHeader from "./auth-header";

class ProjectDataService {
	getAll() {
		return http.get("/templates", { headers: authHeader() });
	}

	get(id) {
		return http.get(`/templates/${id}`);
	}

	create(data) {
		return http.post("/templates", data);
	}

	update(id, data) {
		return http.put(`/templates/${id}`, data);
	}

	delete(id) {
		return http.delete(`/templates/${id}`);
	}

	deleteAll() {
		return http.delete(`/templates`);
	}
}

export default new ProjectDataService();
