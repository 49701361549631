import http from "../http-common";
import authHeader from "./auth-header";

class LocationDataService {
	getAll() {
		return http.get("/locations", { headers: authHeader() });
	}

	get(id) {
		return http.get(`/locations/${id}`);
	}

	create(data) {
		return http.post("/locations", data);
	}

	update(id, data) {
		return http.put(`/locations/${id}`, data);
	}

	delete(id) {
		return http.delete(`/locations/${id}`);
	}

	deleteAll() {
		return http.delete(`/locations`);
	}

	findByTitle(locationname) {
		return http.get(`/locations?locationname=${locationname}`);
	}
}

export default new LocationDataService();
