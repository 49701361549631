import React, { Component } from "react";
import ProjectDataService from "../../services/project.service";
import LocationDataService from "../../services/location.service";
import CustomFieldDataService from "../../services/customfield.service";
import LogDataService from "../../services/log.service";

//import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import Multiselect from "react-select";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";

import Dynamictxt from "./dynamic-txt.component";
import Dynamicmc from "./dynamic-mc.component";
import Dynamicms from "./dynamic-ms.component";

import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};


class EditProject extends Component {
	constructor(props) {
		super(props);
		this.onChangeTitle = this.onChangeTitle.bind(this);
		this.onChangeDescription = this.onChangeDescription.bind(this);
		this.onChangeLocation = this.onChangeLocation.bind(this);
		this.onChangeOfficeFileNumber = this.onChangeOfficeFileNumber.bind(this);
		this.onChangeVPNumber = this.onChangeVPNumber.bind(this);
		this.onChangeBPNumber = this.onChangeBPNumber.bind(this);
		this.updateProject = this.updateProject.bind(this);
		this.retrieveLocations = this.retrieveLocations.bind(this);
		this.retrieveCustomFields = this.retrieveCustomFields.bind(this);
		this.handleOnFieldChange = this.handleOnFieldChange.bind(this);
		this.handleUpdateProject = this.handleUpdateProject.bind(this);
		this.handleEditClose = this.handleEditClose.bind(this);
		this.createTextUI = this.createTextUI.bind(this);
		this.setCustomFields = this.setCustomFields.bind(this);
		this.resetStates = this.resetStates.bind(this);
		this.showcustomFieldsOnEdit = this.showcustomFieldsOnEdit.bind(this);
		this.createLog = this.createLog.bind(this);
		// this.redirectToPage = this.redirectToPage.bind(this);
		// console.log(this.props);

		this.state = {
			id: this.props.editProjectId,
			title: "",
			description: "",
			location: "",
			locationOptions: [],
			officefilenumber: "",
			vpnumber: "",
			bpnumber: "",
			status: true,
			submitted: false,
			showedit: false,
			message: "",
			fieldOptionsArray: [],
			values: [],
			fieldoption: "",
			fieldtxt: "",
			customoptions: [],
			fieldOptionsSelected: [],
			// project: this.props.editProject
			//    redirect: ""
		};
	}

	resetStates() {
		this.setState({
			title: "",
			description: "",
			location: "",
			locationOptions: [],
			officefilenumber: "",
			vpnumber: "",
			bpnumber: "",
			status: true,
			submitted: false,
			showedit: false,
			message: "",
			fieldOptionsArray: [],
			values: [],
			fieldoption: "",
			fieldtxt: "",
			customoptions: [],
			fieldOptionsSelected: [],
		});
	}

	onChangeTitle(e) {
		this.setState({
			title: e.target.value,
		});
	}

	onChangeDescription(e) {
		this.setState({
			description: e.target.value,
		});
	}

	onChangeLocation(e) {
		this.setState({
			location: e.target.value,
		});
	}

	onChangeOfficeFileNumber(e) {
		this.setState({
			officefilenumber: e.target.value,
		});
	}

	onChangeVPNumber(e) {
		this.setState({
			vpnumber: e.target.value,
		});
	}

	onChangeBPNumber(e) {
		this.setState({
			bpnumber: e.target.value,
		});
	}

	async componentDidMount() {
		//   console.log("componentDidMount");
		await this.getProject();
		await this.retrieveLocations();
		await this.retrieveCustomFields();
		//console.log("Props Data: ", this.props);
	}

	async retrieveLocations() {
		const locations = await LocationDataService.getAll();
		//console.log("locations", locations);
		if (locations.statusText === "OK" && locations.status === 200) {
			this.setState({
				locationOptions: locations.data,
			});
		} else {
			console.log("Error Fetching Locations");
		}

		// await LocationDataService.getAll()
		//   .then(response => {
		//     this.setState({
		//       locationOptions: response.data
		//     });
		//     console.log(response.data);
		//   })
		//   .catch(e => {
		//     console.log(e);
		//   });
	}

	async retrieveCustomFields() {
		const customFields = await CustomFieldDataService.getAll();
		//console.log("customFields", customFields);
		if (customFields.statusText === "OK" && customFields.status === 200) {
			const data = customFields.data;
			for (var i = 0; i < data.length; i++) {
				data[i].value = data[i]["id"];
				data[i].label = data[i]["customfieldname"];
			}
			this.setState({ fieldOptionsArray: data });
		} else {
			console.log("Error Fetching Custom Fields");
		}
		// CustomFieldDataService.getAll()
		//   .then(response => {
		//     var res_data = response.data;
		//     console.log(res_data);
		//     for (var i = 0; i < res_data.length; i++) {
		//       res_data[i].key = res_data[i]['id'];
		//       res_data[i].label = res_data[i]['customfieldname'];
		//     }
		//     console.log(res_data);
		//     this.setState({
		//       fieldOptionsArray: res_data
		//     });
		//   })
		//   .catch(e => {
		//     console.log(e);
		//   });
	}

	async getProject() {
		const project = await ProjectDataService.get(this.props.editProjectId);
		//console.log("project", project);
		if (project.statusText === "OK" && project.status === 200) {
			const {
				data: {
					title,
					description,
					location: { _id },
					officefilenumber,
					vpnumber,
					bpnumber,
					customoptions,
				},
			} = project;

			this.setState(
				{
					title,
					description,
					location: _id,
					officefilenumber,
					vpnumber,
					bpnumber,
					customoptions,
				},
				() => {
					//console.log("getProject.getState", this.state);

					const { customoptions } = this.state;
					//  console.log(customoptions);
					//  const fieldOptionsSelected = (customoptions && customoptions.map(option => option.id))
					//   this.setState({ fieldOptionsSelected: fieldOptionsSelected });
					// console.log('getProject.selected', selected);
					//this.handleOnFieldChange(selected);
					if (customoptions) {
						for (var i = 0; i < customoptions.length; i++) {
							customoptions[i].fieldvalue = customoptions[i]["value"];
							customoptions[i].value = customoptions[i]["id"];
						}

						//console.log(customoptions);
					}
					customoptions &&
						customoptions.map((option, index) => {
							this.showcustomFieldsOnEdit([option.id], option, customoptions);
							//this.handleOnFieldChange([option.id], option, customoptions);
						});
				}
			);
		} else {
			console.log("Error Fetching Project");
		}
	}

	handleEditClose() {
		this.props.handleEditClose();
	}

	handleUpdateProject(e) {
		e.preventDefault();
	
		if (this.checkBtn.context._errors.length === 0) {
			this.updateProject();
		}

		this.form.validateAll();

		this.setState({
			successful: false,
			showmessage: true,
		});

		// if (this.checkBtn.context._errors.length === 0) {
		// 	this.updateProject();
		// }
	}

	async updateProject() {
		//console.log(this.state.values);
		const project = await ProjectDataService.get(this.state.id);

		var data = {
			title: this.state.title,
			description: this.state.description,
			location: this.state.location,
			officefilenumber: this.state.officefilenumber,
			vpnumber: this.state.vpnumber,
			bpnumber: this.state.bpnumber,
			status: this.state.status,
			customoptions: this.state.values.map(
				(item) => (delete item.element, item)
			),
		};

		ProjectDataService.update(this.state.id, data)
			.then((response) => {
				this.handleEditClose();
				this.notify("success", response.data.message);
				// this.props.retrieveProjects();

				this.createLog(this.state.id, data, project);
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				this.notify("error", message);
			});
	}

	async createLog(id, data, project) {
		// const project = await ProjectDataService.get(id);
		// var olddata = {};
		// var newdata = {};

		var infodata = {
			activity: "edited",
			activityon: "project",
			activityid: id,
			activityname: data.title,
			userid: this.props.user.id,
			username: this.props.user.username,
			info: [{ olddata: project.data }, { newdata: data }],
		};

		LogDataService.create(infodata)
			.then((response) => {
				//  this.notify("success");
				this.props.retrieveProjects();
			})
			.catch((error) => {
				const message =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				this.setState({
					message: message,
				});
				//this.notify("error");
			});
	}

	// handleOnFieldChange = fieldoption => {

	// handleOnFieldChange(fieldoption) {
	//   console.log(fieldoption);
	//   this.setState({ fieldoption });  /// this is for show selected option
	//   this.setState({values : []});
	//   fieldoption.map((option,index) => {
	//      index = index + 1;
	//     // console.log(option);
	//      CustomFieldDataService.get(option.id)
	//      .then(response => {
	//        var id = response.data.id;
	//        var label = response.data.customfieldname;
	//        var fieldtype = response.data.fieldtype;
	//        var mcmsoptions = response.data.mcmsoptions;
	//         if(fieldtype === 'txt'){
	//          this.setState({
	//            values: [...this.state.values, {element: this.createTextUI(label,index,id), id:id, value:''}]
	//          });
	//        }
	//        else if(fieldtype === 'mc'){
	//          this.setState({
	//            values: [...this.state.values, {element: this.createMcUI(label,mcmsoptions,index,id), id:id, value:''}]
	//          });
	//        }else if(fieldtype === 'ms'){
	//          this.setState({
	//            values: [...this.state.values, {element: this.createMsUI(label,mcmsoptions,index,id), id:id, value:''}]
	//          });
	//        }
	//      })
	//      .catch(e => {
	//        console.log(e);
	//      });
	//    });
	//  }

	showcustomFieldsOnEdit(selected, editOption, fieldoption) {
		//this.setState({ values: [] });
		//  console.log("selected", selected);
		this.setState({ fieldoption }); /// this is for show selected option
		//console.log("................"+JSON.stringify(fieldoption));

		selected.map((option, index) => {
			index = index + 1;
			//console.log(option);
			CustomFieldDataService.get(option)
				.then((response) => {
					var id = response.data.id;
					var label = response.data.customfieldname;
					var fieldtype = response.data.fieldtype;
					var mcmsoptions = response.data.mcmsoptions;
					let selectedValue = editOption.fieldvalue; // change value to field value because of multiselect
					//console.log("response", response);
					if (fieldtype === "txt") {
						this.setState({
							values: [
								...this.state.values,
								{
									element: this.createTextUI(label, index, id, selectedValue),
									id: id,
									value: editOption.fieldvalue,
								},
							],
						});
						this.setCustomFields(id, selectedValue, label);
					} else if (fieldtype === "mc") {
						this.setState({
							values: [
								...this.state.values,
								{
									element: this.createMcUI(
										label,
										mcmsoptions,
										index,
										id,
										selectedValue
									),
									id: id,
									value: editOption.fieldvalue,
								},
							],
						});
						this.setCustomFields(id, selectedValue, label);
					} else if (fieldtype === "ms") {
						this.setState({
							values: [
								...this.state.values,
								{
									element: this.createMsUI(
										label,
										mcmsoptions,
										index,
										id,
										selectedValue
									),
									id: id,
									value: editOption.fieldvalue,
								},
							],
						});
						this.setCustomFields(id, selectedValue, label);
					}

					//console.log("values", this.state.values);
				})
				.catch((e) => {
					console.log(e);
				});
		});
	}

	exists(arr, cid) {
		console.log(arr);
		console.log(cid);
		return arr.some(function (el) {
			return el.id === cid;
		});
	}

	handleOnFieldChange(fieldoption) {
		//  this.setState({ values: [] });
		//  console.log("selected", this.state.values);
		//  console.log("fieldoption", fieldoption);
		this.setState({ fieldoption }); /// this is for show selected option
		// console.log("................"+JSON.stringify(fieldoption));

		//if removed then removed from values
		var values = this.state.values;
		values = values.filter(function (o1) {
			return fieldoption.some(function (o2) {
				return o1.id === o2.id; // return the ones with equal id
			});
		});
		this.setState({ values: values });
		// this.state.values.filter(function (f1){
		//   return
		// })

		// console.log("----------------",this.state.values);

		fieldoption.map((option, index) => {
			index = index + 1;
			console.log(option);
			CustomFieldDataService.get(option.id)
				.then((response) => {
					var id = response.data.id;
					var label = response.data.customfieldname;
					var fieldtype = response.data.fieldtype;
					var mcmsoptions = response.data.mcmsoptions;

					//add only when previously not added
					//    console.log(this.exists(this.state.values, option.id));
					if (!this.exists(this.state.values, option.id)) {
						// alert(fieldtype);
						if (fieldtype === "txt") {
							this.setState({
								values: [
									...this.state.values,
									{
										element: this.createTextUI(label, index, id),
										id: id,
										value: "",
									},
								],
							});
						} else if (fieldtype === "mc") {
							this.setState({
								values: [
									...this.state.values,
									{
										element: this.createMcUI(label, mcmsoptions, index, id),
										id: id,
										value: "",
									},
								],
							});
						} else if (fieldtype === "ms") {
							this.setState({
								values: [
									...this.state.values,
									{
										element: this.createMsUI(label, mcmsoptions, index, id),
										id: id,
										value: "",
									},
								],
							});
						}
					} else {
						//this.showcustomFieldsOnEdit([option.id], editOption, fieldoption);
					}
					console.log("values", this.state.values);
				})
				.catch((e) => {
					console.log(e);
				});
		});
	}

	handleOnFieldOptions(selected) {
		console.log(selected);
	}

	setCustomFields(id, fieldvalue, label) {
		//console.log(label);
		let values = this.state.values;
		var fieldvalues = "";
		if (Array.isArray(fieldvalue)) {
			for (var i = 0; i < fieldvalue.length; i++) {
				fieldvalues += fieldvalue[i].value + ", ";
			}
			fieldvalues = fieldvalues.slice(0, -2);
		} else {
			fieldvalues = fieldvalue;
		}
		this.setState({
			values: values.map((item) => {
				if (item.id === id) {
					item.value = fieldvalues;
					item.label = label;
				}
				return item;
			}),
		});
	}

	createTextUI(label, index, id, selectedValue) {
		return (
			<Dynamictxt
				label={label}
				setCustomFields={this.setCustomFields}
				selectedValue={selectedValue}
				key={id}
				id={id}
			/>
		);
	}

	createMcUI(label, mcmsoptions, index, id, selectedValue) {
		return (
			<Dynamicmc
				label={label}
				msOptionsArray={mcmsoptions}
				selectedValue={selectedValue}
				setCustomFields={this.setCustomFields}
				key={id}
				id={id}
			/>
		);
	}

	createMsUI(label, mcmsoptions, index, id, selectedValue) {
		return (
			<Dynamicms
				label={label}
				msOptionsArray={mcmsoptions}
				selectedValue={selectedValue}
				setCustomFields={this.setCustomFields}
				key={id}
				id={id}
			/>
		);
	}

	notify(type, message) {
		toast(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			type: type,
		});
	}

	render() {
		// const { redirect } = this.state;
		// if (redirect) {
		//   return <Redirect to={this.state.redirect} />
		// }
		const { message, values, fieldOptionsArray, fieldoption } = this.state; //fieldOptionsSelected
		//console.log("fieldOptionsArray", fieldOptionsArray);
		return (
			<div className="submit-form">
				<Modal
					show={this.props.showedit}
					onHide={this.handleEditClose}
					backdrop="static"
					keyboard={false}
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Project</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.submitted ? (
							<div>
								<h4>You submitted successfully!</h4>
							</div>
						) : (
							<div>
								<Form
									onSubmit={this.handleUpdateProject}
									ref={(c) => {
										this.form = c;
									}}
								>
									<div className="row">
										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="title">Title</label>
											<Input
												type="text"
												className="form-control"
												id="title"
												value={this.state.title}
												onChange={this.onChangeTitle}
												name="title"
												validations={[required]}
											/>
										</div>

										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="description">Description</label>
											<Input
												type="text"
												className="form-control"
												id="description"
												value={this.state.description}
												onChange={this.onChangeDescription}
												name="description"
											/>
										</div>

										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="location">Location</label>
											<Select
												className="form-control m-t-10"
												id="location"
												name="location"
												value={this.state.location}
												onChange={this.onChangeLocation}
												validations={[required]}
											>
												<option value="">Select Location</option>
												{this.state.locationOptions
													.sort(function (a, b) {
														var nameA = a.locationname.toUpperCase(); // ignore upper and lowercase
														var nameB = b.locationname.toUpperCase(); // ignore upper and lowercase
														if (nameA < nameB) {
															return -1; //nameA comes first
														}
														if (nameA > nameB) {
															return 1; // nameB comes first
														}
														return 0; // names must be equal
													})
													.map((option, index) => {
														if (this.state.location === option.id) {
															return (
																<option
																	value={option.id}
																	key={index}
																	defaultValue
																>
																	{option.locationname}
																</option>
															);
														} else {
															return (
																<option value={option.id} key={index}>
																	{option.locationname}
																</option>
															);
														}
													})}
											</Select>
										</div>

										{/* <div className="form-group m-b-10">
                        <label htmlFor="location">Location</label>
                        <Input
                          type="text"
                          className="form-control"
                          id="location"
                          required
                          value={this.state.location}
                          onChange={this.onChangeLocation}
                          name="location"
                        />
                      </div> */}
									</div>
									<div className="row">
										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="officefilenumber">
												Office File Number
											</label>
											<Input
												type="text"
												className="form-control"
												id="officefilenumber"
												value={this.state.officefilenumber}
												onChange={this.onChangeOfficeFileNumber}
												name="officefilenumber"
												validations={[required]}
											/>
										</div>
										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="vpnumber"> VP Number</label>
											<Input
												type="text"
												className="form-control"
												id="vpnumber"
												value={this.state.vpnumber}
												onChange={this.onChangeVPNumber}
												name="vpnumber"
											/>
										</div>
										<div className="form-group col-sm-4 m-b-10">
											<label htmlFor="bpnumber"> BP Number</label>
											<Input
												type="text"
												className="form-control"
												id="bpnumber"
												value={this.state.bpnumber}
												onChange={this.onChangeBPNumber}
												name="bpnumber"
											/>
										</div>
									</div>
									<hr />
									<h6>Custom Fields</h6>
									{fieldOptionsArray && fieldOptionsArray.length > 0 ? (
										<div className="form-group col-sm-6 m-b-10">
											<Multiselect
												value={fieldoption}
												onChange={this.handleOnFieldChange}
												isMulti
												options={this.state.fieldOptionsArray}
												defaultValue={fieldoption}
												className="basic-multi-select"
												classNamePrefix="select"
											/>
											{/* <DropdownMultiselect
                        className="form-control col-sm-4"
                        options={fieldOptionsArray}
                        name="fieldoptions"
                        selected={fieldOptionsSelected}
                        handleOnChange={this.handleOnFieldChange} /> */}
										</div>
									) : null}

									{values.length ? values.map((option) => option.element) : ""}
									<hr />
									{message && (
										<div className="form-group">
											{this.state.showmessage && (
												<div
													className={
														this.state.successful
															? "alert alert-success"
															: "alert alert-danger"
													}
													role="alert"
												>
													{message}
												</div>
											)}
										</div>
									)}
									<button className="btn btn-primary col-sm-3 btn-block">
										Update Project
									</button>
									{/* <button onClick={this.saveProject} className="btn btn-success m-t-10">
                        Submit
                      </button> */}
									<CheckButton
										style={{ display: "none" }}
										ref={(c) => {
											this.checkBtn = c;
										}}
									/>
								</Form>
							</div>
						)}
					</Modal.Body>
				</Modal>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(EditProject);
