import React, { Component } from "react";
import FolderDataService from "../../services/folder.service";
import ProjectDataService from "../../services/project.service";

import Modal from 'react-bootstrap/Modal';
import Tree from '@naisutech/react-tree'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
//import  { Redirect } from 'react-router-dom'

class MoveFolder extends Component {
  constructor(props) {
    super(props);
    this.updateFolder = this.updateFolder.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleMoveClose = this.handleMoveClose.bind(this);
    this.onMoveSelect = this.onMoveSelect.bind(this);
   // this.redirectToPage = this.redirectToPage.bind(this);
   // console.log(this.props.currentFolder);
    this.state = {
      folders: [],
      id: null,
      title: "",
      projectid:this.props.projectid,
      parentid: this.props.parentid,
      status: true,
      submitted: false,
      showmessage:true,
      message:"",
      moveButtons: false
    };
  }

  handleMoveClose() {
      this.props.handleMoveClose();
  }

  componentDidMount() {
    this.getFolderList();
  }

  getFolderList(){

    ProjectDataService.get(this.props.projectid)
    .then(response => {
      console.log(response.data);
      this.setState({
        projectName: response.data.title,
        projectDescription: response.data.description,
        projectLocation: response.data.location.locationname,
        projectOFN: response.data.officefilenumber,
        projectVPNumber: response.data.vpnumber,
        projectBPNumber: response.data.bpnumber
      });

      //get folders data
      FolderDataService.getByProject(this.props.projectid)
      .then(response => {
        var res_data = response.data;

        // this is creating top root folder
        var root_folder = {
          id: this.state.projectid,
          parentid: null,
          label: this.state.projectName,
          title: this.state.projectName,
          description: this.state.projectDescription
        }
        res_data.unshift(root_folder);

        // This is for creating lable parentID and itesm required to show tree
        var i;
        for (i = 0; i < res_data.length; i++) {
          res_data[i].label = res_data[i]['title'];
          res_data[i].parentId = res_data[i]['parentid'];
          res_data[i].items = []
        }

        // this code is for add files under the parent folders
        res_data.filter(element => {
          if (element.type === "file") {
            // res_data.filter(function (item) {
            //   if (item['id'] === element.parentId) {
            //     item.items.push(element); // pushing files inside folders
            //   }
            // });
          }
        });
        
        // code is to remove files from the list
        var b = res_data.filter(e => e.type === "file");
        b.forEach(f => res_data.splice(res_data.findIndex(e => e.type === f.type), 1));

        //restrict to show same folder while moving to another
        var c = res_data.filter(e => e.id === this.props.editMoveFileFolderId);
        c.forEach(f => res_data.splice(res_data.findIndex(e => e.id === f.id), 1));

        this.setState({
          folders: res_data
        });
      })
      .catch(e => {
        console.log(e);
      });

    });
  }

  onMoveSelect = selectedNode => {
    // do something with selected node
      this.setState({
        newParentId: selectedNode.id,
        moveButtons: true,
      });
    
  }

  handleMove() {
    this.setState({
        successful: false,
        showmessage:true,
    });
    this.updateFolder();
  }

  updateFolder() {
  //  alert()
    var data = {
      parentid: this.state.newParentId
    };
    
   // console.log(this.state.redirect);
    FolderDataService.update(this.props.editMoveFileFolderId,data)
      .then(response => {
        this.props.retrieveFolders();
        this.notify("success","moved successfully.");
         this.props.onSelect(this.props.currentFolder);
         this.handleMoveClose();
      })
      .catch(error => {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
      
        this.setState({
          message: message
        });
        this.notify("error",message);
      });
  }

  notify(type,message){ 
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        type:type
      }
    );
  }

  render() {
    const { folders, moveButtons, message } = this.state;
      return (
      <div className="submit-form">

        <Modal
          show={this.props.showmove}
          onHide={this.handleMoveClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Move Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.submitted ? (
            <div>
            </div>
            ) : (
            <div>
                <h6>Folders List</h6> <hr />
                <Tree
                  nodes={folders}
                  onSelect={this.onMoveSelect}
                  theme={'light'}
                  size={'full'} grow showEmptyItems />

                  <hr />
                  {message && (
                    <div className="form-group">
                      {this.state.showmessage && (
                      <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                          {message}
                      </div>
                      )}
                    </div>
                  
                  )}
                  <button onClick={() => this.handleMove()} className="btn btn-primary col-sm-4 btn-block" disabled={!moveButtons}>Move</button>
            </div>
          )}
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(MoveFolder);

