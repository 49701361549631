import React, { Component } from "react";
import LocationDataService from "../../services/location.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class AddLocation extends Component {
  constructor(props) {
    super(props);
    this.onChangeLocationname = this.onChangeLocationname.bind(this);
    this.handleSaveLocation = this.handleSaveLocation.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.newLocation = this.newLocation.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      id: null,
      locationname: "",
      status: true,
      submitted: false,
    };
  }

  onChangeLocationname(e) {
    this.setState({
      locationname: e.target.value
    });
  }

  
  handleClose() {
    this.props.handleClose();
  }


  handleSaveLocation(e) {
    e.preventDefault();

    this.setState({
        successful: false,
        showmessage:true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.saveLocation();
    }
  }


  saveLocation() {
  //  alert()
    var data = {
      locationname: this.state.locationname,
      status: this.state.status,
    };
    
   // console.log(this.state.redirect);
   LocationDataService.create(data)
      .then(response => {
        this.handleClose();
        this.newLocation();
        this.notify("success","Location has been created successfully.");
        this.props.retrieveLocations();
        // console.log(this.props);
      })
      .catch(error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          message: message
        });
        this.notify("error",message);
      });
  }

  newLocation() {
    this.setState({
      id: null,
      locationname: "",
      status: false,
      submitted: false
    });
  }

  notify(type,message){ 
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        type:type
      }
    );
  }

  render() {
    const { message } = this.state;
    return (
      <div className="submit-form">
         <Modal
          show={this.props.showadd}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.submitted ? (
              <div>
              </div>
            ) : (
              <div>
                <Form
                    onSubmit={this.handleSaveLocation}
                    ref={(c) => {
                        this.form = c;
                    }}
                >
                  <div className="form-group m-b-10">
                    <label htmlFor="locationname">Location Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="locationname"
                      value={this.state.locationname}
                      onChange={this.onChangeLocationname}
                      name="locationname"
                      validations={[required]}
                    />
                  </div>
                  {message && (
                  <div className="form-group">
                    {this.state.showmessage && (
                  <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                  </div>
                    )}
                  </div>
                
                )}
                <hr />
                <button className="btn btn-sm btn-primary col-sm-2 m-t-10 btn-block">Add Location</button>
                  <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                      this.checkBtn = c;
                      }}
                  />
                </Form>
          </div>
        )}
        </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}

export default AddLocation;

